import { ChangeEvent, FC, useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import "../assets/css/componentStyles/multiRangeSlider.css";

interface MultiRangeSliderProps {
    min: number;
    max: number;
    minValue: number;
    maxValue: number;
    setMinValue: (value: number) => void;
    setMaxValue: (value: number) => void;
    onChange: Function;
}

const MultiRangeSlider: FC<MultiRangeSliderProps> = ({
    min,
    max,
    minValue,
    maxValue,
    setMinValue,
    setMaxValue,
    onChange
}) => {

    const minValRef = useRef<HTMLInputElement>(null);
    const maxValRef = useRef<HTMLInputElement>(null);
    const range = useRef<HTMLDivElement>(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value: number) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minValue);
            const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minValue, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxValue);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxValue, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minValue, max: maxValue });
    }, [minValue, maxValue, onChange]);

    return (
        <div className="container">
            <input
                type="range"
                min={min}
                max={max}
                value={minValue}
                ref={minValRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.min(+event.target.value, maxValue - 1);
                    setMinValue(value);
                    event.target.value = value.toString();
                }}
                className={classnames("thumb thumb--zindex-1", {
                    "thumb--zindex-5": minValue > max - 100
                })}
            />

            <input
                type="range"
                min={min}
                max={max}
                value={maxValue}
                ref={maxValRef}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const value = Math.max(+event.target.value, minValue + 1);
                    setMaxValue(value);
                    event.target.value = value.toString();
                }}
                className="thumb thumb--zindex-1"
            />

            <div className="slider z-[-1]">
                <div className="slider__track"></div>
                <div ref={range} className="slider__range"></div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
