import React, {useEffect} from 'react';
import SignupForm from "../../components/auth/signup-form";
import {useNavigate} from "react-router-dom";
import {isAuthenticated} from "../../services/authService";
import {Home} from "../../routes/constants";

const SignUp = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated()){
            navigate(Home);
        }
    }, []);
    return (<div><SignupForm/></div>);
};

export default React.memo(SignUp);
