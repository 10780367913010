import React, { ChangeEvent, useEffect, useRef, useState, useContext } from 'react';
import { Button, Avatar } from "flowbite-react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import DropDown from "./DropDown";
import { getUser, handleLogout } from '../../services/userService';
import { UserType, initializeUser } from '../../model/user/userInterface';
import { isAuthenticated } from "../../services/authService";
import { Contact } from "../../routes/constants";
import Modal from "react-modal";
import DropdownSidebar from "./DropdownSidebar";
import ContactUsForm from "../ContactUsForm"
import { searchQueries } from "../../routes"
import Logo from "../../assets/images/logo/png/Black logo - no background.png";

const Header: React.FC = () => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchContext = useContext(searchQueries)

    const contactModal = {
        content: {
            width: "40%",
            padding: "0px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: "1000"
        },
    };

    const [listOpen, setListOpen] = useState<boolean>(false);
    const [isCategorySelected, setIsCategorySelected] = useState<boolean>(false);
    const [user, setUser] = useState<UserType>(initializeUser)
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showSearchbar, setShowSearchbar] = useState(false);
    const [contactModalIsOpen, setContactModalIsOpen] = useState<boolean>(false);
    const [avatarMenu, setAvatarMenu] = useState<boolean>(false);
    const [isAdmin, setiIsAdmin] = useState<boolean>(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const toggleSearchbar = () => {
        setShowSearchbar(!showSearchbar)
    }


    // hide the Side bar
    const hideMenu = () => {
        setShowMenu(false)
    }

    const toggleAvatarMenu = () => {
        setAvatarMenu(!avatarMenu)

    }

    const getSearchbarValues = (e: ChangeEvent<HTMLInputElement>) => {
        searchContext?.setSearchbarText(e.target.value)
        setListOpen(true)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // handleMakeQuery('cat', '-') // JB added
        handleMakeQuery("q", searchContext?.searchbarText);
        setListOpen(false);
        // searchContext?.setSearchbarText("");
        searchContext?.handleSearch({search: searchContext?.searchbarText, category: ''});
        inputRef.current?.blur();
    }

    const closeContactModal = () => {
        setContactModalIsOpen(false)
    }
    const openContactModal = () => {
        setContactModalIsOpen(!contactModalIsOpen)
    }

    useEffect(() => {
        const getCurrentUser = async () => {
            const user = await getUser();
            setUser(user);
            if (user.role == "Manager"){
                setiIsAdmin(true);
            }

        }
        getCurrentUser();
        if (window.location.pathname === '/') {
            searchContext?.setSearchbarText("");
        }
    }, [location.pathname])

    useEffect(() => {
        const testObj: any = {
            q: 'search',
            cat: 'category',
            subcat: 'subcategory',
            ratings: 'ratings',
            course_type: 'course_type',
            distance: 'distance',
            level: 'level',
            price: 'price',
            course_duration: 'course_duration'
        };
        let testQuery: any = {};
        for (const [key, value] of searchParams) {
            testQuery[testObj[key]] = value
        }
        if (testQuery.hasOwnProperty('search')) {
            searchContext?.setSearchbarText(testQuery['search']);
        }
    }, []);

    const handleQuery = () => {
        handleMakeQuery('cat', '-') // JB added
        handleMakeQuery("q", searchContext?.searchbarText);
        setListOpen(false);
        setShowSearchbar(showSearchbar)
        inputRef.current?.blur();
    };

    const handleMakeQuery = (name: string, value: string) => {
        let testQuery: any = {};
        for (const [key, value] of searchParams) {
            testQuery[key] = value
        }
        testQuery[name] = value
        delete testQuery["cat"];
        let searchQuery = '/search/?'
        for (const property in testQuery) {
            searchQuery = searchQuery + `&${property}=${testQuery[property]}`
        }
        if (searchQuery === '/search/?') {
            searchQuery = '/search/';
        } else if (searchQuery.startsWith('&')) {
            searchQuery = searchQuery.slice(1);
        }
        navigate(searchQuery);
    };

    useEffect(() => {
        let handler = () => {
            setListOpen(false);
        }
        window.addEventListener("click", handler)
    },[]);

    const handleAvatar = (firstName: string | any, lastName: string | any) => {
        return firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase()
    }

    useEffect(() => {
        if(showMenu){
            document.body.style.overflow = "hidden";
        }
        if(!showMenu){
            document.body.style.overflow = "unset";
        }
    }, [showMenu]);

    // const clearFilter = () => {
    //     setFilter({})
    //     setMin(0)
    //     setMax(5000)
    //     setSelectedOptions(null)
    //     searchContext?.setSearchbarText("")
    //     searchContext?.handleSearch({}, true)
    //     let searchQuery = '/search/'
    //     navigate(searchQuery);
    // }


    return (
        <div>
            <nav className="shadow-md bg-white py-2 px-3 md:block hidden">
                <div className="flex items-center justify-between ">
                    <div className="flex w-[85%] md:w-[61%] lg:w-[71%] gap-8">
                        <img onClick={() => {
                            isAuthenticated() ? navigate('/') : navigate('/login')
                        }}
                        className ="self-center whitespace-nowrap cursor-pointer text-2xl font-bold dark:text-white"
                        src= {Logo}
                        alt = "Ace academy logo"
                        width = "80"
                        height= "80"
                            >
                        </img>
{/*                         
                        <h1 onClick={() => {
                            isAuthenticated() ? navigate('/') : navigate('/login')
                        }}
                            className="self-center whitespace-nowrap cursor-pointer text-2xl font-bold dark:text-white">GL</h1> */}


                        <div className="flex items-center justify-center w-[12%]"
                             onClick={() => {
                                 navigate('/search')}}>
                            <div className="hover:text-[#5624d0] gap-6 font-medium text-sm">
                                <button className="">
                                    Alle cursussen
                                </button>
                            </div>

                            {/*{*/}
                            {/*    isAuthenticated() && <DropDown setIsCategorySelected={setIsCategorySelected} isCategorySelected={isCategorySelected}/>*/}
                            {/*}*/}
                        </div>

                        {
                            isAuthenticated() &&
                            <form className="flex items-center w-4/5 relative" onSubmit={(e) => handleSubmit(e)}>
                                <div className="w-4/5 relative">
                                    <div className="relative">
                                        <div
                                            className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                      clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                        <input type="text" id="simple-search" autoComplete="off"
                                               className="border-1 border-black w-full text-gray-900 text-sm rounded-full pl-10 p-[.56rem] dark:placeholder-gray-400 dark:text-white"
                                               placeholder="Zoek cursus" value={searchContext?.searchbarText}
                                               onChange={getSearchbarValues}
                                               ref={inputRef}
                                        />
                                    </div>
                                    {(listOpen && searchContext?.searchbarText) &&
                                        <div className="absolute w-full border border-gray-300 z-10">
                                            <div className="bg-white shadow-md px-3 py-2">
                                                <ul>
                                                    <li className='flex gap-4 items-center cursor-pointer'
                                                        onClick={handleQuery}>
                                                        <svg aria-hidden="true"
                                                             className="w-4 h-4 text-gray-900 dark:text-gray-900"
                                                             fill="currentColor" viewBox="0 0 20 20"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd"
                                                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                                  clipRule="evenodd">
                                                            </path>
                                                        </svg>
                                                        <span>{searchContext?.searchbarText}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        }
                    </div>
                    {/*contactUs, complete profile, avatar*/}
                    <div className="gap-6 flex items-center justify-center">
                        <div className="hover:text-[#5624d0] gap-6 font-medium text-sm">
                            <button onClick={openContactModal}>
                                Contact
                            </button>
                            <Modal
                                isOpen={contactModalIsOpen}
                                style={contactModal}
                                onRequestClose={closeContactModal}
                                ariaHideApp={false}
                                className=""
                            >
                                <ContactUsForm closeContactModal={closeContactModal}/>
                            </Modal>
                        </div>
                        {
                            user && user?.id ?
                                <div className="flex flex-wrap gap-2 items-center cursor-pointer">
                                    <div className="relative" onMouseOver={() => setIsOpen(true)}
                                         onMouseOut={() => setIsOpen(false)}>
                                        <Avatar
                                            alt="Default avatar with alt text"
                                            img={user.image ? user.image : ""}
                                            placeholderInitials={handleAvatar(user?.firstName, user?.lastName)}
                                            rounded={true}
                                        />
                                    </div>
                                    {
                                        isOpen &&
                                        <div className="absolute top-8 right-3 bg-transparent z-10"
                                             onMouseOver={() => setIsOpen(true)} onMouseOut={() => setIsOpen(false)}>
                                            <ul>
                                                <li className="!bg-transparent py-px px-4 text-transparent">
                                                    hidden
                                                </li>
                                                <li className="p-4 bg-white border border-gray-200 cursor-pointer">
                                                    <Avatar
                                                        img={user.image ? user.image : ""}
                                                        placeholderInitials={handleAvatar(user?.firstName, user?.lastName)}
                                                        rounded={true}
                                                        size="lg"
                                                    >
                                                        <div className="text-base font-bold dark:text-white">
                                                            <div className="hover:text-secondary capitalize">
                                                                {user?.firstName} {user?.lastName}
                                                            </div>
                                                            <div className="text-xs text-gray-500 dark:text-gray-400">
                                                                {user?.email}
                                                            </div>
                                                        </div>
                                                    </Avatar>
                                                </li>
                                                <li className="flex px-4 pt-4 pb-2 text-sm border-l border-r border-gray-200 cursor-pointer bg-white hover:text-secondary"
                                                    onClick={() => {
                                                        navigate('/profile')
                                                        setIsOpen(false);
                                                    }}>
                                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor"
                                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                                    </svg>
                                                    <span>
                                                Mijn profiel
                                              
                                            </span>
                                                </li>
                                     
                                                 { isAdmin &&
                                                <li className="flex px-4 pt-4 pb-2 text-sm border-l border-r border-gray-200 cursor-pointer bg-white hover:text-secondary"
                                                    onClick={() => {
                                                        navigate('/management-dashboard')
                                                        setIsOpen(false);
                                                    }}>
                                                    
                                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.709,7.438H8.292c-0.234,0-0.427,0.192-0.427,0.427v8.542c0,0.234,0.192,0.427,0.427,0.427h3.417c0.233,0,0.426-0.192,0.426-0.427V7.865C12.135,7.63,11.942,7.438,11.709,7.438 M11.282,15.979H8.719V8.292h2.563V15.979zM6.156,11.709H2.74c-0.235,0-0.427,0.191-0.427,0.426v4.271c0,0.234,0.192,0.427,0.427,0.427h3.417c0.235,0,0.427-0.192,0.427-0.427v-4.271C6.583,11.9,6.391,11.709,6.156,11.709 M5.729,15.979H3.167v-3.416h2.562V15.979zM17.261,3.167h-3.417c-0.235,0-0.427,0.192-0.427,0.427v12.812c0,0.234,0.191,0.427,0.427,0.427h3.417c0.234,0,0.427-0.192,0.427-0.427V3.594C17.688,3.359,17.495,3.167,17.261,3.167 M16.833,15.979h-2.562V4.021h2.562V15.979z"></path>
                                                </svg>
                                                    <span>
                                                Management dashboard
                                            </span>
                                                </li>

                                            }
                                                <li className="flex px-4 py-4 text-sm bg-white border-l border-b border-r border-gray-200 font-normal cursor-pointer hover:text-secondary"
                                                    onClick={() => {
                                                        setUser(initializeUser)
                                                        handleLogout();
                                                        setIsOpen(false);
                                                        navigate('/login');
                                                    }}>
                                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                                    </svg>
                                                    <span>
                                                Uitloggen
                                           </span>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="flex gap-4 justify-end">
                                    <Link to="/login">
                                        <Button className="!rounded-none focus:!ring-transparent" color="light">
                                            Login
                                        </Button>
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </nav>

            {/*!.....mobile screen.....!*/}

            <div className="">
                <div className="flex items-center justify-between p-3 relative shadow-md md:hidden">
                    {/*menu-icon*/}
                    <div className="cursor-pointer" onClick={toggleMenu}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </div>
                    {/*logo*/}
                    <div>
                        <h1 className=" relative self-center whitespace-nowrap cursor-pointer text-2xl font-bold dark:text-white"
                            onClick={() => {
                                isAuthenticated() ? navigate('/') : navigate('/login')
                            }}> Ace Acedemy </h1>
                    </div>
                    {/* search-icon */}
                    {
                        isAuthenticated() ?
                            <div className="flex gap-8">
                                <div className="cursor-pointer relative" onClick={toggleSearchbar}>
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                            </div> :
                            <div className="flex gap-8 text-transparent">
                                <div className="relative">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                            </div>
                    }
                </div>
                {/*sideBar*/}
                <div className={`${ showMenu ? "flex gap-8 fixed top-0 left-0 bg-Sidebar w-full h-screen z-30 md:hidden" : ""}`}>
                    <div className={`top-0 left-0 fixed w-full h-full z-10 ${showMenu ? "translate-x-0" : "-translate-x-[500%]"} duration-500`}>
                        <div className={`bg-white flex flex-col w-[18rem] h-screen overflow-y-auto`}>
                            {/*Avatar*/}
                            <div className="">
                                {isAuthenticated() ?
                                    <div className="">
                                        <div className="flex items-center gap-6 bg-gray-200 cursor-pointer" onClick={toggleAvatarMenu}>
                                            <div className="avatar">
                                                <Avatar
                                                    img={user?.image ? user.image : ""}
                                                    placeholderInitials={handleAvatar(user?.firstName, user?.lastName)}
                                                    rounded={true}
                                                    size="xl"
                                                    className="!justify-start px-3 py-5"
                                                >
                                                    <div className="text-base font-bold dark:text-white">
                                                        <div className="hover:text-secondary">
                                                            {user?.firstName} {user?.lastName}
                                                        </div>
                                                        <div className="text-xs text-gray-500 dark:text-gray-400">
                                                            {user?.email}
                                                        </div>
                                                    </div>
                                                </Avatar>
                                            </div>
                                        </div>
                                        {/*profile & logout div start*/}
                                        {avatarMenu &&
                                            <div className="fixed top-0 w-[18rem] bg-white h-screen">
                                                <div
                                                    className="bg-gray-200 p-4 flex items-center gap-5 cursor-pointer"
                                                    onClick={() => setAvatarMenu(false)}
                                                >
                                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                                                    </svg>
                                                    <span className="font-base">Menu</span>
                                                </div>
                                                <div
                                                    className="flex flex-col p-2 h-screen">
                                                    <ul>
                                                        <li className="flex p-1">
                                                            <Link to={"/profile"} className="flex items-center cursor-pointer" onClick={hideMenu}>
                                                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                                                                </svg>
                                                                <span className="hover:text-secondary">
                                                                    Mijn profiel
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li className="flex p-1">
                                                            <Link to="/login" className="flex items-center cursor-pointer"
                                                                  onClick={() => {
                                                                      setUser(initializeUser)
                                                                      handleLogout();
                                                                      setIsOpen(false);
                                                                      navigate('/login');
                                                                      hideMenu();
                                                                  }}
                                                            >
                                                                <svg className="w-5 h-5 mr-2" fill="none"
                                                                     stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                                                </svg>
                                                                <span className="hover:text-secondary">
                                                            Logout
                                                        </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        {/*profile & logout div end*/}
                                    </div> :
                                    <div className="flex flex-col px-6 pt-6 gap-4">
                                        <Link to="/login" onClick={hideMenu}>
                                            <button className="text-blue-600 hover:text-black">
                                                Sign In
                                            </button>
                                        </Link>
                                    </div>
                                }
                            </div>
                            <div className="px-6 bg-white">
                                {
                                    isAuthenticated() &&
                                    <div className="flex flex-col my-4">
                                        <Link to={"/profile"}>
                                            <button
                                                onClick={hideMenu}
                                                className="flex justify-start hover:text-secondary">
                                                Complete Profile
                                            </button>
                                        </Link>
                                    </div>
                                }
                                <div className="hover:text-[#5624d0] gap-6 font-medium">
                                    <Link to={Contact}>
                                        <button onClick={hideMenu}>
                                            Contact
                                        </button>
                                    </Link>
                                </div>
                                <hr className="my-4"/>
                                {/*Categories in Sidebar*/}
                                <div className="z-0">
                                    <div>
                                        { isAuthenticated() && <DropdownSidebar hideMenu={hideMenu}/> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center cursor-pointer top-6 lg:hidden fixed left-[19rem]"
                            onClick={hideMenu}
                        >
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/*long search bar*/}
            {
                showSearchbar &&
                <div className="fixed top-0 w-full h-full bg-blue-50 z-50 md:hidden">
                    {
                        showSearchbar && (
                            <div className="bg-white fixed z-10 left-0 w-full absolute h-full">
                                <div className="flex items-center justify-between fixed top-0 w-full py-4 border-b border-gray-200">
                                    <form className="flex items-center md:hidden w-full" onSubmit={(e) => handleSubmit(e)}>
                                        <div className="relative w-full">
                                            <div className="flex relative w-full">
                                                <div
                                                    className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                                    </svg>
                                                </div>
                                                <input type="text" id="simple-search" autoComplete="off"
                                                       className="border-0 w-full text-gray-900 text-sm pl-10"
                                                       placeholder="Zoek cursus" value={searchContext?.searchbarText}
                                                       onChange={getSearchbarValues}
                                                       ref={inputRef}
                                                />
                                            </div>
                                            {(listOpen && searchContext?.searchbarText) &&
                                                <div className="absolute w-screen top-[3.3rem]">
                                                    <div className="bg-white px-3 py-2">
                                                        <ul>
                                                            <li className='flex gap-4 items-center cursor-pointer' onClick={handleQuery}>
                                                                <svg aria-hidden="true" className="w-4 h-4 text-gray-900 dark:text-gray-900" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                                                </svg>
                                                                <span className="cursor-pointer">{searchContext?.searchbarText}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </form>
                                    <div className="cursor-pointer mr-3" onClick={() => setShowSearchbar(false)}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
};

export default React.memo(Header);
