import React, { useState, useContext } from 'react';
import {dropdownData} from "../DropdownData";
import {useNavigate, useSearchParams} from "react-router-dom";
import { searchQueries } from "../../routes"

interface InnerListData {
    name: string;
    data: string[];
}

interface ListData {
    name: string;
    // data: InnerListData[];
}

interface DropDownProps {
    hideMenu: any;
}

const DropdownSidebar = ({hideMenu}: DropDownProps) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const searchContext = useContext(searchQueries)
    const list: ListData[] = dropdownData.list;

    const handleCategoryNavigation = (category: string) => {
        
        handleMakeQuery('cat', category.toLowerCase())
        searchContext?.handleSearch({category: category})
    }

    const handleMakeQuery = (name: string, value: string) => {
        let testQuery:any = {};
        for (const [key, value] of searchParams){
            testQuery[key] = value
        }
        testQuery[name] = value

        let searchQuery = '/search/?'
        for (const property in testQuery) {
            searchQuery = searchQuery + `&${property}=${testQuery[property]}`
        }
        // console.log('searchQuery',searchQuery)
        navigate(searchQuery);
    }

    return (
        <div>
            <div className="w-full">
                <div>
                    <div className="w-full ">
                        {
                            list?.map((item, idx:number) => (
                                <div key={idx} onClick={() => handleCategoryNavigation(item?.name)}>
                                    <li className='pb-[7px] flex text-primary justify-between items-center hover:text-secondary cursor-pointer'>
                                        <span className="font-normal" onClick={hideMenu}>{item?.name}</span>
                                    </li>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownSidebar;
