import React, {useState} from 'react';
import {Spinner} from "flowbite-react";
import {saveContactDetails} from "../services/FirebaseService";
import {toast} from "react-toastify";
import history from "../helpers/history";
import emailjs from '@emailjs/browser';

interface ContactFormProp {
    closeContactModal?: () => void;
}



const ContactUsForm = ({ closeContactModal }:ContactFormProp) => {

    interface ContactData {
        fullName: string;
        email: string;
        message: string;
        agree: any | true | false;
    }
    const [loader, setLoader] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [contactUserDetail, setContactUserDetail] = useState<ContactData>({
        fullName: "",
        email: "",
        message: "",
        agree: isChecked
    });

    const handleChangeContactDetail = (e:any) => {
        setIsChecked(!isChecked)
        const {name, value} = e.target
        setContactUserDetail({...contactUserDetail, [name] : value})
    }

    const handleContactDetails = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setLoader(true)
        const {fullName, email, message} = contactUserDetail;
        if (fullName && email && message) {
            try {
                const res:any = await saveContactDetails(contactUserDetail)
                if (res){
                    toast.success("Bedankt voor je bericht, we zullen binnen twee werkdagen contact met je opnemen.")
                }
                
                toast.success("Bedankt voor je bericht, we zullen binnen twee werkdagen contact met je opnemen.")
                setLoader(false)
                if (closeContactModal) {
                    closeContactModal();
                }
            } catch (e:any){
                toast.warn(e.message)
                setLoader(false)
            }
        } else if(!fullName && !email) {
            toast.warn("please enter your full name and email")
        }
        emailjs.sendForm("service_62uwbxy", "template_r5klw5t", e.target as HTMLFormElement , process.env.REACT_APP_EMAILJS_APIKEY)
    }
    const handleCancel = () => {
        if ( window.innerWidth < 768) {
            history.back();
        } else {
            if (closeContactModal) {
                closeContactModal();
            }
        }
    }


  
    return (
        <div className="">
            <div className="shadow-md p-6 flex flex-col gap-3">
                <h1 className="text-xl font-bold">Neem contact met ons op!</h1>
            </div>
            <div className="md:p-4 sm:px-8 px-4 py-8">
                <form className="flex items-center flex flex-col gap-8" onSubmit={handleContactDetails}>
                    <div className="relative w-full">
                        <label htmlFor="" className="font-semibold"> Naam <span className="text-red-500">*</span> </label>
                        <input
                            type="text"
                            id="simple-search"
                            autoComplete="off"
                            value={contactUserDetail.fullName}
                            name = "fullName"
                            required
                            onChange={handleChangeContactDetail}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-5 p-2.5 mt-2"
                        />
                    </div>

                    <div className="relative w-full">
                        <label htmlFor="" className="font-semibold"> E-mail <span className="text-red-500">*</span> </label>
                        <input
                            type="text"
                            id="simple-search"
                            autoComplete="off"
                            value={contactUserDetail.email}
                            name = "email"
                            onChange={handleChangeContactDetail}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-5 p-2.5 mt-2"
                        />
                    </div>

                    <div className="relative w-full">
                        <label htmlFor="" className="font-semibold"> Bericht <span className="text-red-500">*</span> </label>
                        <textarea
                            className="w-full h-[10rem] mt-2 rounded-lg"
                            onChange={handleChangeContactDetail}
                            value={contactUserDetail.message}
                            name="message"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-end w-full gap-3">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="text-white bg-gray-800 hover:bg-gray-900 font-medium text-sm px-5 py-2.5 mr-2 mb-2">
                                Cancel
                            </button>
                        <button
                            type="submit"
                            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium text-sm px-5 py-2.5 mr-2 mb-2 !min-w-[5.4rem]"
                        >
                            {
                                loader ? <Spinner aria-label="Default status example" /> : "Verstuur"
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUsForm;
