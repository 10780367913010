import { AuthToken } from '../model/auth/authInterface';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./FirebaseService";

export const getToken = () => {
        const token: AuthToken = {
            uid: sessionStorage.getItem('uid'),
            idToken:  sessionStorage.getItem('idToken'),
            accessToken:  sessionStorage.getItem('accessToken'),
            expiresIn:  sessionStorage.getItem('expiresIn'),
            email:  sessionStorage.getItem('email')
        }
        return token as AuthToken
}

export const setToken = (tokenData: any) => {
    sessionStorage.setItem('idToken', tokenData?.user?.accessToken);
    sessionStorage.setItem('uid', tokenData?.user?.uid);
    sessionStorage.setItem('email', tokenData?.user?.email);
    sessionStorage.setItem('accessToken', tokenData?.user?._tokenResponse?.accessToken);
    sessionStorage.setItem('expiresIn', tokenData?.user?._tokenResponse?.expiresIn);
}

export const sendPasswordResetLink = async (email: string) => {
    try {
        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for
            // this URL must be whitelisted in the Firebase Console.
            url: 'http://localhost:3000/login',
            // This must be true for email link sign-in.
        };
        return await sendPasswordResetEmail(auth, email, actionCodeSettings);
    } catch (err: any) {
        console.error(err);
        return err;
    }
};

export const checkAuthentication = async () => {
    const user = auth.currentUser;
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;

        return true;
    } else {
        console.log('User logged out');
        return false;
    }
}
export const handleAuthAction = async (callback: any) => {
    if( await checkAuthentication()) {
        callback();
    }
}
export const isAuthenticated = () => {
    return !!sessionStorage.getItem('uid');
}