import React, { useEffect, useState, useContext } from 'react';
import { Accordion } from "flowbite-react";
import { FilterTypes, Filter } from "../../model/filter/filterInterface";
import { useSearchParams, useNavigate } from "react-router-dom";
import PriceSlider from "../PriceSlider";
import "../../../src/assets/css/componentStyles/PriceSlider.css"
import LocationSelect from "../LocationSelect";
import { getCourseTypeAndCount } from "../../services/courseService";
import { searchQueries } from "../../routes/index"
import { dropdownData } from "../DropdownData";
interface ListData {
    name: string
}
const Filters: React.FC<FilterTypes> = ({
    filter,
    setFilter,
    minValue,
    setMinValue,
    maxValue,
    setMaxValue,
    selectedOptions,
    setSelectedOptions,
}: FilterTypes) => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const list: ListData[] = dropdownData?.list;
    const searchContext = useContext(searchQueries);

    const [courseTypeAndCount, setCourseTypeAndCount] = useState();

    useEffect(() => {
        handleMakeQuery('price', minValue.toString() + '-' + maxValue.toString(), true);
        let filterObj: Filter | any = { ...filter, price: { min: minValue, max: maxValue } };
        setFilter(filterObj);
    }, [minValue, maxValue]);

    const handleChangeSelect = (selectedOption: any) => {
        if (setSelectedOptions) {
            setSelectedOptions(selectedOption);
        }
        let locationList: string[] = []
        selectedOption.forEach((item: any) => locationList.push(item?.label));
        let isEmpty = !(Array.isArray(locationList) && locationList.length === 0)
        handleMakeQuery('location', locationList.toString(), isEmpty);
        let filterObj: Filter | any = { ...filter, location: locationList };
        setFilter(filterObj);
    };

    const handleGetValue = (e: React.ChangeEvent<HTMLInputElement>, value: string, key: string) => {
        e.preventDefault()
        const checked = e.target.checked;
        value = checked ? value : '';
        handleMakeQuery(key, (key === 'category' ? encodeURIComponent(value) : value), checked);
        let filterObj: Filter | any = { ...filter, [key]: value };
        setFilter(filterObj);
    };

    useEffect(() => {
        if (Object.keys(filter)?.length)
            searchContext?.handleSearch(filter);

    }, [filter]);

    useEffect(() => {
        const testObj: any = {q: 'search' ,cat: 'category', subcat: 'subcategory', course_type: 'course_type', location: 'location', price: 'price', course_duration: 'course_duration'};
        let testQuery: any = {};
        for (const [key, value] of searchParams) {
            if (key === "location") {
                testQuery[testObj[key]] = value.split(",")
                let makeOptionObject: any = value.split(',').map((val) => ({ label: val, value: val }))
                if (setSelectedOptions) {
                    setSelectedOptions(makeOptionObject)
                }
            } else if (key === 'price') {
                let makeObj: any = value.split('-') //"split()" splits a string into an array of substrings, and returns the array of substrings.
                parseInt(makeObj) // convert the substring into integer.
                if (setMinValue) {
                    setMinValue(makeObj[0])
                }
                if (setMaxValue) {
                    setMaxValue(makeObj[1])
                }
            } else {
                testQuery[testObj[key]] = key === 'category' ?  decodeURIComponent(value) : value
            }
        }
        let filterObj: Filter | any = { ...filter, ...testQuery };
        setFilter(filterObj);

    }, []);

    const handleMakeQuery = (name: string, value: string, checked: boolean) => {
        let testQuery: any = {};
        for (const [key, value] of searchParams) {
            testQuery[key] = value
        }
        testQuery[name] = value
        if (!checked) {
            delete testQuery[name]
        }
        let searchQuery = '/search/?'
        for (const property in testQuery) {
            searchQuery = searchQuery + `&${property}=${testQuery[property]}`
        }
        navigate(searchQuery);
    };

    const getCourseTypeAndCountCallback = async () => {
        const data: any = await getCourseTypeAndCount();
        if (data) {
            setCourseTypeAndCount(data);
        }
    };

    useEffect(() => {
        getCourseTypeAndCountCallback();
    }, []);



    return (
        <div>
            <Accordion collapseAll={true} className="border-0">
                <Accordion.Panel>
                    <Accordion.Title className="!bg-white !text-lg !font-bold !text-primary focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title">
                        Cursus type
                    </Accordion.Title>
                    <Accordion.Content className="accordion_content">
                        <ul className="mb-2">
                            {
                                courseTypeAndCount && Object.keys(courseTypeAndCount).length > 0 &&
                                Object.keys(courseTypeAndCount).map((typeKey) =>
                                    <li className="mb-2 flex items-center" key={typeKey}>
                                        <input type="checkbox"
                                            className="w-3.5 h-3.5"
                                            value={filter.course_type}
                                            checked={filter.course_type === typeKey}
                                            onChange={(e) => handleGetValue(e, typeKey, 'course_type')}
                                        />
                                        <label className="ml-3 text-sm font-normal"> {typeKey} </label>
                                        {/* <label className="ml-3 text-sm font-normal"> {typeKey} <span style={{color:"#737A7C"}}>({courseTypeAndCount[typeKey]})</span></label> */}
                                    </li>
                                )
                            }
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_type}
                                    checked={filter.course_type === "all"}
                                    onChange={(e) => handleGetValue(e, 'all', 'course_type')}
                                />
                                <label className="ml-3 text-sm font-normal"> All </label>
                            </li>
                        </ul>
                    </Accordion.Content>
                </Accordion.Panel>

                <Accordion.Panel>
                    <Accordion.Title className="!bg-white !text-lg !font-bold !text-primary focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title">
                        Locatie
                    </Accordion.Title>
                    <Accordion.Content className="accordion_content">
                        <ul className="mb-2">
                            <li className="mb-2 flex items-center">
                                <LocationSelect selectedOptions={selectedOptions} handleChangeSelect={handleChangeSelect} />
                            </li>
                        </ul>
                    </Accordion.Content>
                </Accordion.Panel>

                <Accordion.Panel>
                    <Accordion.Title className="!bg-white !text-lg !font-bold !text-primary focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title">
                        Prijs
                    </Accordion.Title>
                    <Accordion.Content className="accordion_content">
                        <div className="mb-2">
                            <div className="mb-2 flex flex-col">
                                <PriceSlider
                                    minValue={minValue}
                                    maxValue={maxValue}
                                    setMinValue={setMinValue}
                                    setMaxValue={setMaxValue}
                                />
                            </div>
                        </div>
                    </Accordion.Content>
                </Accordion.Panel>

                <Accordion.Panel>
                    <Accordion.Title className="!bg-white !text-lg !font-bold !text-primary focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title">
                        Duur
                    </Accordion.Title>
                    <Accordion.Content className="accordion_content">
                        <ul className="mb-2">
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "1 hour"}
                                    onChange={(e) => handleGetValue(e, '1 hour', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 1 hour </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "1 - 3 hours"}
                                    onChange={(e) => handleGetValue(e, '1 - 3 hours', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 1 - 3 hours </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "3 - 5 hours"}
                                    onChange={(e) => handleGetValue(e, '3 - 5 hours', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 3 - 5 hours </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "6 - 8 hours"}
                                    onChange={(e) => handleGetValue(e, '6 - 8 hours', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 6 - 8 hours </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "8 - 16 hours"}
                                    onChange={(e) => handleGetValue(e, '8 - 16 hours', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 8 - 16 hours </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "17 hours"}
                                    onChange={(e) => handleGetValue(e, '17 hours', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> 17+ hours </label>
                            </li>
                            <li className="mb-2 flex items-center">
                                <input type="checkbox"
                                    className="w-3.5 h-3.5"
                                    value={filter.course_duration}
                                    checked={filter.course_duration === "all"}
                                    onChange={(e) => handleGetValue(e, 'all', 'course_duration')}
                                />
                                <label className="ml-3 text-sm font-normal"> All </label>
                            </li>
                        </ul>
                    </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                    <Accordion.Title className="!bg-white !text-lg !font-bold !text-primary focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title">
                        Categorieën
                    </Accordion.Title>
                    <Accordion.Content className="accordion_content">
                        <ul className="mb-2">
                            {list?.map((item: any, idx: number) => (
                                <div className='mb-2' key={idx} >
                                    <input
                                        type="checkbox"
                                        className='w-3.5 h-3.5'
                                        value={filter.category}
                                        checked={decodeURIComponent(filter.category) === item.name}
                                        onChange={(e) => handleGetValue(e, item.name, "category")}
                                    />
                                    <label className="text-sm ml-2 font-normal">{item?.name}</label>
                                </div>
                            ))}
                        </ul>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
            <hr />
        </div>
    );
};

export default React.memo(Filters);
