import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const CardDetailSkeleton: React. FC = () => {
    return (
        <React.Fragment>
            <div className="md:container mx-auto">
                {[0]?.map((x: number, idx:  number) =>
                    <div className="block" key={idx}>
                        <div className="flex items-center mb-16">
                            <div className="">
                                <Skeleton width="17.2rem" height="172px"/>
                            </div>
                            <div className="justify-between w-full ml-4">
                                <div className="w-full">
                                    <div className="flex justify-between relative">
                                        <h3 className="font-semibold text-2xl"> <Skeleton width="25rem" height="1rem"/> </h3>
                                        <div className="flex flex-col text-right">
                                            <span className="font-bold text-xl text-gray-500"> <Skeleton width="4rem" height="20px"/></span>
                                            <div className="absolute mt-8 text-left"> <Skeleton width="8rem" height="2rem"/></div>
                                        </div>
                                    </div>
                                    <div className="text-base font-medium"> <Skeleton width="25rem" height="0.5rem"/></div>
                                    <div className="text-base"> <Skeleton width="5rem" height="0.5rem"/></div>
                                    <div className="flex gap-2">
                                        <div><Skeleton width="6rem" height="0.5rem"/></div>
                                        <div><Skeleton width="1rem" height="0.5rem"/></div>
                                        <div><Skeleton width="5rem" height="0.5rem"/></div>
                                    </div>
                                    {/*Description*/}
                                    <div><Skeleton width="7rem" height="0.5rem"/></div>
                                    <div className="">
                                        <div className="text-base mt-1"> <Skeleton width="18rem" height="40px"/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Description*/}
                        <div className="">
                            <div className="">
                                <div className="">
                                    <div>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton className="mb-8" width="100%" height=".5rem"/>
                                        <Skeleton width="10%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton className="mb-8" width="50%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton className="mb-8" width="70%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                        <Skeleton width="100%" height=".5rem"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default CardDetailSkeleton;
