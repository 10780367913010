import { useState, useEffect } from 'react';
import axios from 'axios';
import {getDashboardData} from "../services/dashboardService";
import { getUser } from "../services/userService";
import '../assets/css/componentStyles/managementDashboard.css'

interface Post {
  id: number;
  title: string;
  body: string;
}

const Table = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [allPosts, setAllPosts] = useState<Post[]>([]);
  const [coursesSearch, setCoursesSearch] = useState('');
  const [isFilterDataPresent, setIsFilterDataPresent] = useState(false);

//   const getDashboarddata = async () => {
//     const user = await getUser();
//     const dashboarddata: any = await getDashboardData(user.managerEmail);
//     console.log('dashboarddata',dashboarddata)
//     console.log(user.managerEmail)
//     // setCourseList(courses);
//     // setLoading(false);
// }
//
//   useEffect(() => {
//     getDashboarddata();
// }, []);



  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get<Post[]>(
        'https://jsonplaceholder.typicode.com/posts'
      );
      setPosts(response.data);
      setAllPosts(response.data);
    };
    fetchData();
  }, []);

  const handleTableSearch = () => {
      const searchedString = coursesSearch.toLowerCase().trim();
      const filteredString = posts.filter((post) =>
          post.title.toLowerCase().includes(searchedString)
      );
    console.log('filteredString', filteredString)
    console.log('posts', posts)
    if(filteredString.length > 0 && coursesSearch){
      setIsFilterDataPresent(true)
      setPosts(filteredString);
    }else{
      setPosts(allPosts);
      setIsFilterDataPresent(false)
    }
  };
  const handleRemoveSearch = () => {
    setCoursesSearch('')
    setPosts(allPosts);
    setIsFilterDataPresent(false)
  };
  return (
    <div className="mt-5 md:mt-12 relative overflow-x-auto">
    <h3 className="font-extrabold min-h-[2.4rem] mb-1 text-base tracking-tight text-primary dark:text-white leading-tight line-clamp-2"> Afgenomen cursussen</h3>
      <div className="pb-4 flex items-center bg-white dark:bg-gray-900">
        <label htmlFor="simple-search" className="sr-only">Search</label>
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
            </svg>
          </div>
          <input type="text" id="simple-search"
                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                 placeholder="Search courses" required
                 value={coursesSearch}
                 onChange={(e) => setCoursesSearch(e.target.value)}/>
        </div>
        <button type="submit" onClick={handleTableSearch}
                className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:outline-none  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
          <span className="sr-only">Search</span>
        </button>

        {isFilterDataPresent &&
          <button type="submit" onClick={() => {
            handleRemoveSearch()
          }}
                  className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">

            <span className="">Remove</span>
          </button>
        }

      </div>
      <div id='myDivId' className="h-[500px] overflow-auto myScrollbar">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-t-xl">
          <tr>
            <th
                style={{ backgroundColor: 'blue', borderTopLeftRadius: '8px', borderTopRightRadius: '0px' }}
                scope="col"
                className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold text-white  uppercase tracking-wider"
            >
              Werknemer
            </th>
            <th
                style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                scope="col"
                className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold text-white uppercase tracking-wider"
            >
              Cursus
            </th>
            <th
                style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                scope="col"
                className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold text-white uppercase tracking-wider"
            >
              Bedrag
            </th>
            <th
                style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '8px' }}
                scope="col"
                className="px-6 py-3 bg-gray-100 text-left text-sm font-semibold text-white uppercase tracking-wider"
            >
              Status
            </th>
          </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
          {posts.map((post) => (
              <tr key={post.id}>
                <td className="px-6 py-4 whitespace-nowrap">{post.id}</td>
                <td className="px-6 py-4 whitespace">{post.title}</td>
                <td className="px-6 py-4 whitespace-nowrap">{post.title}</td>
                <td className="px-6 py-4 whitespace-nowrap">{post.title}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default Table;
