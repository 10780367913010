import React, {useEffect, useState} from 'react';
import {initializeUser, UserType} from "../model/user/userInterface";
import {getUser} from "../services/userService";
import Home from "../assets/images/test_resized.jpg";
import {isAuthenticated} from "../services/authService";
import {handleDateFormat} from "../helpers/dateFormates";


const SectionUpperView: React. FC = () => {
    const [user, setUser] = useState<UserType>(initializeUser)

    useEffect(() => {
        const getCurrUser = async () => {
            const newUser: UserType = await getUser()
            setUser(newUser)
        }
        getCurrUser();
    },[])


    return (
        <div>
            {/*!---HomePage Image---!*/}
            <div className="block">
                <div className="flex justify-center relative">
                    <img src={Home} className="w-full" alt="home-banner" />
                    { isAuthenticated() &&
                    <div className="lg:block hidden shadow-lg left-[3.5%] top-[10%] font-roboto text-primary absolute block p-6 max-w-sm bg-white border border-gray-200 w-2/6">
                        <h5 className="mb-2 text-3xl font-bold tracking-tight capitalize">
                            Welkom {user && user?.id ? user.firstName : ""}!
                        </h5>
                        <div className="block text-2xl">
                            {/* <p className="font-medium">
                                Here are you personal
                            </p>
                            <p className="font-medium my-2">
                                recommendations
                            </p> */}
                            {
                                user?.budget && user?.budget !== 'undefined' &&
                                <p className="text-secondary text-base font-normal">
                                    Huidig leerbudget: <span className="font-bold !text-base">€{user.budget}</span>
                                </p>
                            }
                            { user?.budget_exp_date_yyyy_mm_dd &&
                                <p className="font-medium text-secondary text-base">
                                    Verloopt over: <span className="font-bold !text-base">{handleDateFormat(user.budget_exp_date_yyyy_mm_dd)}</span>
                                </p>
                            }
                        </div>

                    </div>
                    }

                </div>
            </div>
            {/*!---personal recommendations---!*/}
            <div className="flex justify-between mt-7">
                <div className="block font-roboto text-primary">
                    {/* <div>
                        <h1 className="text-[1.6rem] font-medium tracking-normal sm:ml-3 ml-4 mr-4 sm:mr-0">Personal Recommendations</h1>
                    </div>
                    <div>
                        <p className="my-3 font-normal text-lg sm:ml-3 ml-4">Chosen from 18,000 courses with new additions published every month.</p>
                    </div> */}
                    {/*<div>*/}
                    {/*    <ul className="flex flex-wrap text-base font-medium text-center text-gray-500 ml-6">*/}
                    {/*        <li className="mr-2">*/}
                    {/*            <a href="/" aria-current="page"*/}
                    {/*               className="inline-block px-2 !pl-0 text-[#6A6F73] focus:text-[#1C1D1F]">Python</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="mr-2">*/}
                    {/*            <a href="/"*/}
                    {/*               className="inline-block px-2 hover:text-gray-500 focus:text-gray-700">Excel</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="mr-2">*/}
                    {/*            <a href="/"*/}
                    {/*               className="inline-block px-2 hover:text-gray-500 focus:text-gray-700">Web Development</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="mr-2">*/}
                    {/*            <a href="/"*/}
                    {/*               className="inline-block px-2 hover:text-gray-500 focus:text-gray-700">JavaScript</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a href="/" className="inline-block px-2 text-gray-500 focus:text-gray-700">Data Science</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a href="/" className="inline-block px-2 text-gray-500 focus:text-gray-700">AWS Certification</a>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <a href="/" className="inline-block px-2 text-gray-500 focus:text-gray-700">Drawing</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
                {/*percentage circle*/}
                {/*{*/}
                {/*    isAuthenticated() &&*/}
                {/*    <div className="shadow-md shadow-[#888888] font-roboto flex items-center justify-between py-3 px-4 bg-[#C6A665] w-[30%]">*/}
                {/*        <div className="block">*/}
                {/*            <h1 className="text-2xl font-extrabold mb-2 text-white">Complete your profile</h1>*/}
                {/*            <p className="w-[84%] text-white text-base font-normal font-roboto">We will suggest you better courses based upon your preference</p>*/}
                {/*        </div>*/}
                {/*        <div className="">*/}
                {/*            <div className="overflow-hidden flex justify-center flex-col items-center relative w-[6.2rem] h-[6.2rem] bg-[#9D8551] rounded-full dark:bg-gray-600">*/}
                {/*                <svg className=" w-14 h-12 text-gray-400" fill="#C4B693" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path>*/}
                {/*                </svg>*/}
                {/*                <span className="text-white font-bold font-[system-ui] text-2xl">70%</span>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        </div>
    );
};

export default SectionUpperView;
