import { initializeApp } from "firebase/app";
import { getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
} from "firebase/auth";

import {getFirestore, collection, doc, addDoc, setDoc, query, where, getDocs} from "firebase/firestore"
import { setToken } from "./authService";
import { UserType, contactUsDetail } from "../model/user/userInterface";
import { toast } from "react-toastify";
import {getUserByEmail, setUser} from "./userService";
import {CollectionStrings} from "../model/constants/collectionNames";
import 'firebase/firestore';
import { ListFormat } from "typescript";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "go-learn-75d38.firebaseapp.com",
    projectId: "go-learn-75d38",
    storageBucket: "go-learn-75d38.appspot.com",
    messagingSenderId: "590399727527",
    appId: "1:590399727527:web:5bf2c51b6f4187258db882",
    measurementId: "G-J7PREW2ZHX"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(firebase);

export const db = getFirestore();

export const updateProfile: any = async ( profileData: any, documentId: any, functionName:any, firstName:any, lastName:any,  education:any, gender:any, hardskills:ListFormat, softskills:ListFormat) => {
    try {
  
        const currentUser = auth.currentUser;
        if (currentUser) {

            const docRef = doc(db, CollectionStrings.EMPLOYEES, documentId);
        
            await setDoc(docRef, {
                functionName: functionName,
                firstname:firstName,
                lastname:lastName,
                education:education,
                gender:gender,
                hardskills:hardskills,
                softskills:softskills,
                profileCompleted:'Complete'
              }, { merge: true});
              sessionStorage.setItem('firstName', firstName);
              sessionStorage.setItem('lastName', lastName);
              sessionStorage.setItem('functionName', functionName);
              sessionStorage.setItem('education', education);
              sessionStorage.setItem('gender', gender);
              sessionStorage.setItem('hardskills', hardskills.toString());
              sessionStorage.setItem('softskills', softskills.toString());
              sessionStorage.setItem('profileCompleted', 'Complete');
        }

    } catch (err: any){
     toast.error(err.message)
    }
};


export const logInWithEmailAndPassword: any = async (email: any, password: any) => {
    try {
        const res =  await signInWithEmailAndPassword(auth, email, password);
        if (res?.user && res?.user?.uid) {
            await setToken(res);
            let userData: any = {}
            let docId: any ={}
            // @ts-ignore
            const q = query(collection(db, CollectionStrings.EMPLOYEES), where("email", "in", [res?.user?.email, res?.user?.email?.charAt(0).toUpperCase() + res?.user?.email?.slice(1)]));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots

                userData = doc.data();
                docId = doc.id;
            });
            
            await setUser(userData, docId);
        }
        return res;
    } catch (err: any) {
        // alert(err.message);
        toast.error(err.message);
    }
};

export const saveContactDetails = async(contactObj:contactUsDetail | any) => {
    try {
        await addDoc(collection(db, CollectionStrings.CONTACTUS), {
            fullName:contactObj.fullName,
            email: contactObj.email,
            message: contactObj.message
        });

    } catch (err: any) {
        alert(err.message);
        toast.error(err.message);
    }
}

export const registerWithEmailAndPassword = async (userObj: UserType | any) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, userObj?.email, userObj?.password);
        const user = res.user;
        await setToken(res);
        const userData: any = await getUserByEmail(user.email)
        await setUser(userData,user);
        return res;
    } catch (err: any) {
        toast.error(err.message);
    }
};

export const logout = () => {
  auth.signOut();
};