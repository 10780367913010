import React from 'react';
import './App.css';
import AppRoutes from "./routes/index";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <AppRoutes/>
        <ToastContainer
            position="bottom-right"
            theme="dark"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
        />
    </div>
  );
}

export default App;
