import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {getCourseLocationInFilterSection} from "../services/courseService";

interface TypeOptions {
    value: string;
    label: string;
}

interface TypeOption extends Array<TypeOptions>{}

interface PropsTypes {
    selectedOptions: any;
    handleChangeSelect: any
}

const LocationSelect = ({selectedOptions, handleChangeSelect}: PropsTypes) => {

    const [location, setLocation] = useState<TypeOption>();

    const handleGetLocations = async() => {
        try {
            const getLocation: any = await getCourseLocationInFilterSection()
            const convertIntoArray = Object.keys(getLocation[0]).map(key => {
                return getLocation[0][key]
            })
            const mapTheArray: any = convertIntoArray.map((item:any) => {
                return {value: item.city, label: item.city}
            })
            setLocation(mapTheArray)
        }catch(e:any) {
            console.log(e.message)
        }
    }
    useEffect(() => {
        handleGetLocations()
    },[])

    return (
        <div className="flex flex-col justify-center items-center h-full">
            <Select
                className="w-[240px]"
                placeholder="Kies locatie"
                options={location}
                value={selectedOptions}
                defaultValue={selectedOptions}
                onChange={handleChangeSelect}
                isSearchable={true}
                isMulti
            />
        </div>
    );
};

export default LocationSelect;
