import React, { useEffect, useState } from 'react';
import "../assets/css/componentStyles/CourseDetailComponent.css"
import { Accordion, Rating, Spinner } from "flowbite-react";
import Modal from "react-modal";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCourseDetails, getBookedCourse, getcourseDates } from "../services/courseService";
import { CourseType } from "../model/course/courseInterface";
import CardDetailSkeleton from "../CardDetailSkeleton";
import { isAuthenticated } from "../services/authService";
import { toast } from "react-toastify";
import { handleBookCourse } from "../services/courseService";
import { getUser } from "../services/userService";
import { UserType } from "../model/user/userInterface";
import Star from "./shared/Star";
import ContactUsForm from "./ContactUsForm";
import { Contact } from "../routes/constants";
import { ImLocation } from "react-icons/im"
import { BsFillCheckCircleFill } from "react-icons/bs"
import { BiCalendarEvent } from "react-icons/bi"
import { IoIosArrowDown } from "react-icons/io"


interface CourseDay {
    id: string;
    date: string;
    startTime: string;
    endTime: string;
    city?: string;
}

interface Programrun_list {
    coursday_list: CourseDay[];
    endDate: string;
    price: string;
    location?: string;
    programrun_id: string;
    startDate: string;
    status: string;
}

interface DatesAndLocation {
    programrun_list: Programrun_list[];
}

const CourseDetailComponent = ({ course }: CourseType | any) => {
    const [courseDetail, setCourseDetail] = useState<CourseType>(course);
    const [popUpIsOpen, setPopupIsOpen] = useState<boolean>(false)
    const [dateModalIsOpen, setDateModalIsOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);
    const [bookCourseDetail, setBookCourseDetail] = useState({});
    const [contactModalIsOpen, setContactModalIsOpen] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [bookedCourseList, setBookedCourseList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedLocationIndex, setSelectedLocationIndex] = useState<number | null>(null);
    const [datesAndLocation, setDatesAndLocation] = useState<DatesAndLocation>()
    const [showAllDatesAndLocations, setShowAllDatesAndLocations] = useState<boolean>(false)


    const toggleText = () => {
        setShowAllDatesAndLocations(!showAllDatesAndLocations);
    };


    const { id } = useParams();
    const navigate = useNavigate();

    const popupStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const contactModal = {
        content: {
            width: "30%",
            padding: "0px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const dateModal = {
        content: {
            width: window.innerWidth > 955 ? "30%" : "70%",
            height: "99%",
            padding: "0px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const formatDate = (inputDate: any) => {
        const months = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'
        ];

        const parts = inputDate.split('-');
        const year = parts[0];
        const month = parseInt(parts[1], 10);
        const day = parts[2];

        const formattedDate = `${day} ${months[month - 1]} ${year}`;

        return formattedDate;
    };

    // const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // setSelectedLocation(event.target.value as string);
    // setDateModalIsOpen(!dateModalIsOpen)
    // const selectedIndex = event.target.selectedIndex;
    // setSelectedLocationIndex(selectedIndex !== -1 ? selectedIndex : null);

    // const selectedIndex = event.target.selectedIndex;
    // setSelectedLocationIndex(selectedIndex);
    // setSelectedLocation(event.target.value);
    // };

    const handleBookButton = (name: string, id: any, price: string, startDate?: any, endDate?: any, location?: any) => {
        if (!isAuthenticated()) {
            toast("Login om deze cursus te boken!")
        } else if(startDate !== undefined || endDate !== undefined || location !== undefined) {
            openPopUp();
            setBookCourseDetail({ course_name: name, course_id: id, course_startDate: startDate, course_endDate: endDate, course_price: price, course_location: location })
        } else {
            openPopUp()
            setBookCourseDetail({ course_name: name, course_id: id, course_price: price })
        }
    }


    const handleBookThisCourse = async () => {
        setLoader(true)
        const user: UserType = await getUser();
        // const course = { ...bookCourseDetail, User_UID: user.id, firstname: user.firstName, lastname: user.lastName, price: courseDetail.price, location: selectedLocation, company: user.company, email: user.email, course_url: courseDetail.course_url, managerEmail: user.managerEmail }
        const course = { ...bookCourseDetail, User_UID: user.id }
    
        try {
            setLoader(true);
            const res = await handleBookCourse(course);
            if (res?.status === 200) {
                setLoader(false)
                toast.success("Course successfully booked!")
                closePopup();
                await handleBookedCourseList();
            }
            setDateModalIsOpen(false)
        } catch (error: any) {
            toast.warn(error.message)
            closePopup()
        }
    }

    const openPopUp = () => {
        setPopupIsOpen(!popUpIsOpen)
    }

    const closePopup = () => {
        setPopupIsOpen(false)
    }

    const openDateModal = () => {
        setDateModalIsOpen(!dateModalIsOpen)
    }

    const closeDateModal = () => {
        setSelectedLocationIndex(null);
        setSelectedLocation("");
        setDateModalIsOpen(false)
    }

    const handleGetCourseDetails = async () => {
        try {
            setLoading(true)
            const course: any = await getCourseDetails(id);
            setCourseDetail(course);
            setLoading(false)
        } catch (e: any) {
            console.log(e.message)
        }
    }

    const getCoursesLocationAndDates = async (organisation_edudux: string, program_id: string) => {
        try {

            setLoading(true)
            const course: any = await getcourseDates(organisation_edudux, program_id)
            setDatesAndLocation(course)
            setLoading(false)
            return course
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }


    const closeContactModal = () => {
        setContactModalIsOpen(false)
    }

    const openContactModal = () => {
        if (window.innerWidth < 768) {
            navigate(Contact)
        } else {
            setContactModalIsOpen(!contactModalIsOpen)
        }
    }

    useEffect(() => {
        if (id) {
            handleGetCourseDetails();
        }
        setSelectedLocation(courseDetail?.location[0])
    }, [id])


    useEffect(() => {
        if (courseDetail?.organisation && courseDetail?.programId) {
            const { organisation, programId } = courseDetail;
            getCoursesLocationAndDates(organisation, programId)
        }

    }, [])

    const handleBookedCourseList = async () => {
        const user: UserType = await getUser();
        if (user) {
            const bookedData: any = await getBookedCourse(user?.id);
            if (bookedData) {
                setBookedCourseList(bookedData);
            }
        }
    }

    useEffect(() => {
        handleBookedCourseList();
    }, []);

    const checkIsBooked = (id: string) => {
        let isBooked = false;
        if (bookedCourseList) {
            bookedCourseList.forEach((list: any) => {
                if (list?.course_id === id) {
                    isBooked = true;
                }
            })
        }
        return isBooked;
    }

    return (
        <div className="h-[75vh]">
            {
                loading && <CardDetailSkeleton />
            }
            {
                !loading &&
                <div className="container mx-auto md:p-0 p-4">
                    <div className="block">
                        <div className="flex sm:flex-row flex-col md:items-center">
                            <div className="block mb-4 sm:mb-0">
                                <img src={courseDetail?.image || "https://img-b.udemycdn.com/course/240x135/394676_ce3d_5.jpg"} alt="courses-banner" className="min-w-[270px] h-[176px] sm:w-auto w-full" />
                            </div>
                            <div className="justify-between w-full sm:ml-4">
                                <div className="">
                                    <div className="flex justify-between">
                                        <div className="flex flex-col md:w-[63%] w-[72%]">
                                            <h3 className="sm:mb-1 mb-0 text-lg sm:text-xl tracking-tight text-primary dark:text-white leading-tight font-extrabold">{courseDetail?.name}</h3>
                                            <p className="lg:block hidden lg:max-w-[32rem] md:max-w-[400px] max-w-[250px] mb-1 sm:text-base text-sm text-primary !leading-tight dark:text-gray-400 font-normal">
                                                {courseDetail?.description_small}
                                            </p>
                                            <p className="text-base font-medium sm:mb-1 mb-0">{courseDetail?.type}</p>
                                            <Rating className="sm:mb-1 md:mb-1 mb-1">
                                                <Star reviews={courseDetail?.review_score?.toString().replace(',', '.')} />
                                                <p className="ml-2 tracking-tight text-sm font-bold text-[#1c1d1f]">
                                                    {courseDetail?.review_score ? Math.round(parseFloat(courseDetail?.review_score)) : ""}
                                                </p>
                                                <Link
                                                    to="#"
                                                    className="text-sm ml-2 font-normal underline underline-offset-2 hover:no-underline dark:text-white"
                                                >
                                                    {courseDetail?.number_of_reviews} {courseDetail?.number_of_reviews && "reviews"}
                                                </Link>
                                            </Rating>
                                            <div className="flex flex-col">
                                                <div className="">
                                                    <p className="text-sm mb-1">{courseDetail?.educational_institution}</p>
                                                </div>
                                                <div className="block md:hidden">
                                                    <p className="font-semibold text-medium text-gray-500 mb-1">€{courseDetail?.price}</p>
                                                </div>
                                                <div className='flex gap-3 align-items-center'>
                                                    <div>
                                                        <Modal
                                                            isOpen={dateModalIsOpen}
                                                            style={dateModal}
                                                            onRequestClose={closeDateModal}
                                                            ariaHideApp={false}
                                                        >
                                                            {/* Display selected location */}
                                                            {/* <p className='flex gap-1 items-center'><ImLocation className='text-[#D04D79] text-xl' /> {selectedLocation}</p> */}
                                                            <div>
                                                                <div className='pt-2 pb-2 pl-1'>
                                                                    <h2 className='text-xl font-bold'>Startdata en plaatsen</h2>
                                                                </div>
                                                                <div>
                                                                    <div className='m-[5px] border '>
                                                                        {
                                                                            datesAndLocation?.programrun_list && (
                                                                                datesAndLocation?.programrun_list.slice(0, showAllDatesAndLocations ? datesAndLocation?.programrun_list.length : 3).map((programrunList: Programrun_list, idx: number) => {

                                                                                    return (

                                                                                        <div key={idx} className='mb-1 px-3 py-2' style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                                                                                            <div className='flex items-center justify-between'>
                                                                                                <div>
                                                                                                    {/* <p className='flex gap-1 items-center text-[#757575]'><ImLocation className='text-[#D04D79] text-xl' /> {item?.coursday_list?[0].city}</p> */}
                                                                                                    <p className='text-lg font-bold'>{programrunList?.startDate} tot {programrunList?.endDate} </p>
                                                                                                </div>
                                                                                                <button
                                                                                                    className='px-4 py-2 bg-[#548235] text-white cursor-pointer'
                                                                                                    onClick={() => handleBookButton(courseDetail?.name, courseDetail?.id, programrunList?.startDate as string, programrunList?.endDate, courseDetail?.price, programrunList?.coursday_list[0].city)}

                                                                                                >Inschrijven</button>
                                                                                            </div>
                                                                                            {/* <p className='flex gap-2 items-center text-[#9BC353] mt-2'><BsFillCheckCircleFill className='text-lg' /> Startgarantie</p> */}
                                                                                            <Accordion collapseAll={true} className="border-0">
                                                                                                <Accordion.Panel>
                                                                                                    <Accordion.Title className="!bg-white !text-[#6394B6] focus:!bg-white dark:focus:border-none focus:ring-transparent accordion_title w-48">
                                                                                                        Toon rooster
                                                                                                    </Accordion.Title>
                                                                                                    <Accordion.Content className="accordion_content">
                                                                                                        <div className=''>
                                                                                                            {
                                                                                                                programrunList?.coursday_list?.length > 0 && programrunList.coursday_list.sort((a, b) => a.date.localeCompare(b.date)).map((courseDay:any) =>
                                                                                                                        <div className='flex items-center gap-1'>
                                                                                                                            <BiCalendarEvent className='text-xl' />
                                                                                                                            <p>{formatDate(courseDay?.date)}, {courseDay?.startTime} - {courseDay?.endTime}, {courseDay?.city} </p>
                                                                                                                        </div>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                    </Accordion.Content>
                                                                                                </Accordion.Panel>
                                                                                            </Accordion>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            )
                                                                        }
                                                                        {
                                                                            !showAllDatesAndLocations &&
                                                                            <div className='px-3 py-2 flex items-center gap-2 !text-[#6394B6] cursor-pointer' onClick={toggleText}>
                                                                                <p>Laat nog meer startdata zien</p>
                                                                                <IoIosArrowDown />
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="block md:hidden my-[10px]">
                                                {checkIsBooked(courseDetail?.id) ?
                                                    <button className="px-6 py-2 text-black border border-black mt-3"
                                                    >
                                                        Booked
                                                    </button>
                                                    :
                                                    <button className="px-12 py-3 text-center text-white cursor-pointer bg-[#548235] hover:bg-[#5a8600] max-w-fit"
                                                        //  onClick={() => handleBookButton(courseDetail?.name, courseDetail?.id, courseDetail?.price)}
                                                        onClick={openDateModal}
                                                    >Book this course</button>
                                                }
                                            </div>

                                        </div>
                                        <div className=" text-right md:block hidden">
                                            <p className="font-bold text-xl text-gray-500 ">€{courseDetail?.price}</p>
                                            <div className="flex gap-2">
                                                <div>
                                                    {
                                                        (datesAndLocation?.programrun_list && datesAndLocation?.programrun_list.length == 1 && datesAndLocation?.programrun_list[0]?.location === 'Online') ?
                                                            <p className="px-6 py-2 text-white mt-3 bg-[#A9A9A9] inset-0 shadow">
                                                                Online
                                                            </p>
                                                            :
                                                           <div>
                                                               {
                                                                   datesAndLocation?.programrun_list ?
                                                                       <button className="px-6 py-2 text-white mt-3 cursor-pointer bg-[#548235] hover:bg-[#5a8600] inset-0 shadow"
                                                                               onClick={openDateModal}
                                                                               disabled={checkIsBooked(courseDetail?.id) || datesAndLocation === undefined}
                                                                       >
                                                                           Voorkeursdatum
                                                                       </button>
                                                                       :
                                                                       <p className="px-6 py-2 text-white mt-3 bg-[#A9A9A9] inset-0 shadow">
                                                                           Datum en locatie is op aanvraag
                                                                       </p>
                                                               }
                                                           </div>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        checkIsBooked(courseDetail?.id) ?
                                                            <button className="px-6 py-2 text-black mt-3 inset-0 shadow shadow-gray-300">
                                                                Booked
                                                            </button>
                                                            :
                                                            <button className="px-6 py-2 text-white mt-3 cursor-pointer bg-[#548235] hover:bg-[#5a8600] inset-0 shadow"
                                                                    onClick={() => handleBookButton(courseDetail?.name, courseDetail?.id, courseDetail?.price)}
                                                            >
                                                                Inschrijven
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Book course PopUp*/}
                        <div className="flex items-center justify-center my-8">
                            <Modal
                                isOpen={popUpIsOpen}
                                style={popupStyle}
                                onRequestClose={closePopup}
                                ariaHideApp={false}
                            >
                                <div className="text-center">
                                    <div className="flex flex-col gap-8">

                                        <div>
                                            <p className="text-base">
                                                Deze boeking is nog afhankelijk van datum en locatie. Eén van onze werknemers neemt binnen 1-3 dagen contact met u en uw manager op om de cursus in te plannen <br />
                                            </p>
                                        </div>
                                        <div>
                                            <button
                                                onClick={handleBookThisCourse}
                                                className="bg-[#548235] hover:bg-[#5a8600] px-12 py-4 text-white font-semibold min-w-[17rem]"
                                            >
                                                {
                                                    loader ? <Spinner aria-label="Default status example" /> : "Neem contact met mij op"
                                                }
                                            </button>
                                        </div>
                                        <div>
                                            <p className="underline underline-offset-2 cursor-pointer hover:text-blue-600"
                                                onClick={openContactModal}>
                                                Andere vragen? Laat het ons vooral weten.
                                            </p>
                                            <Modal
                                                isOpen={contactModalIsOpen}
                                                style={contactModal}
                                                onRequestClose={closeContactModal}
                                                ariaHideApp={false}
                                            >
                                                <ContactUsForm closeContactModal={closeContactModal} />
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        {/*Description*/}
                        <div className="description pb-6">
                            <div className="description" dangerouslySetInnerHTML={{ __html: courseDetail?.description_full }} />
                            {/* <select
                                name="locations"
                                id="locations-select"
                                onChange={selectChange}
                                className="w-1/2 min-w-[19.4rem] border-gray-400"
                                onClick={openDateModal}
                            ></select> */}
                        </div>
                    </div>
                </div>
            }
        </div>


    );
};

export default React.memo(CourseDetailComponent);
