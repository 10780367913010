import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CardSkeleton: React.FC = () => {
    return (
        <div className="md:container md:mx-auto">
            <div className="block">
                <div>
                    <Skeleton width="100%" className="sm:h-[35.5rem] h-[18rem]"/>
                </div>
            </div>
            {/*!---personal recommendations---!*/}
            <div className="flex justify-between mt-6">
                <div className="block">
                    <div>
                        <h1 className="text-2xl font-medium mb-6"><Skeleton width="21rem" height="1.5rem"/></h1>
                    </div>
                    <div>
                        <p className="mb-7"><Skeleton width="36rem" height="0.5rem"/></p>
                    </div>
                </div>
            </div>
            <div className="p-6 border">
                <div className="pl-3">
                    <div className="block mt-2">
                        <h1 className="text-xl font-medium mb-2"><Skeleton width="20rem" height="1rem"/></h1>
                        <p className="w-1/2 mb-2">
                            <Skeleton width="45rem" height="0.5rem"/>
                            <Skeleton width="35rem" height="0.5rem"/>
                        </p>
                        <p className="font-bold mb-2">
                            <Skeleton width="10rem" height="0.5rem"/>
                            <Skeleton width="24rem" height="1rem"/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CardSkeleton;
