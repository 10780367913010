import { useState, useEffect } from 'react';
import '../assets/css/componentStyles/managementDashboard.css'

type Booking = {
    Date: number;
    FirstName: string;
    LastName: string;
    Course: string;
    coursePrice: number | null;
    Status: string;
    [key: string]: any; // Index signature to allow any string key
};

type Props = {
    bookingData: Booking[];
};

const BookingDashboard: React.FC<Props> = ({ bookingData }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchColumn, setSearchColumn] = useState('FirstName');

    const filteredData = bookingData.filter((booking) => {
        const columnValue = booking[searchColumn];
        if (columnValue === null) return false;
        return columnValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
    });

    const handleSearchColumnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchColumn(event.target.value);
        setSearchQuery('');
    };


    if (!Array.isArray(bookingData)) {
        return <p>No booking data available.</p>;
    }
    return (
        <div id='myDivId' className="h-[500px] overflow-auto myScrollbar mt-8">
            <div className="flex items-center mb-4">
                <input
                    className="border border-gray-300 rounded focus:border-gray-500"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <select
                    value={searchColumn}
                    onChange={handleSearchColumnChange}
                    className="ml-2 p-2 w-[180px] border border-gray-300 rounded"
                >
                    <option value="Date">Date</option>
                    <option value="FirstName">First Name</option>
                    <option value="LastName">Last Name</option>
                    <option value="Course">Course</option>
                    <option value="coursePrice">Course Price</option>
                    <option value="Status">Status</option>
                </select>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400  ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 rounded-t-xl">
                    <tr>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '8px', borderTopRightRadius: '0px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white  uppercase tracking-wider"
                        >
                            Date
                        </th>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white uppercase tracking-wider"
                        >
                            First Name
                        </th>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white uppercase tracking-wider"
                        >
                            Last Name
                        </th>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white uppercase tracking-wider"
                        >
                            Course
                        </th>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white uppercase tracking-wider"
                        >
                            Course Price
                        </th>
                        <th
                            style={{ backgroundColor: 'blue', borderTopLeftRadius: '0px', borderTopRightRadius: '8px' }}
                            scope="col"
                            className="px-6 py-3 bg-gray-100 text-left text-xs md:text-sm font-semibold text-white uppercase tracking-wider"
                        >
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {filteredData.length > 0 ? filteredData.map((booking, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">
                                {new Date(booking.Date).toLocaleString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">{booking.FirstName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">{booking.LastName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">{booking.Course}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">
                                {booking.coursePrice !== null ? `$${booking.coursePrice}` : 'N/A'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">{booking.Status}</td>
                        </tr>
                    )) :
                        <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-primary">
                                No data found
                            </td>
                        </tr>
                    }

                </tbody>
            </table>
        </div>

    );
};

export default BookingDashboard;
