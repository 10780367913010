import React from 'react';

const WithLoading = (Component: any, LoaderComponent: any) => {
    return function WithLoadingComponent({isLoading, ...props} : any) {
        if (!isLoading) return (<Component {...props} />);
        return ( <LoaderComponent /> )
    }
};

export default WithLoading;
