import  {FunctionComponent, useEffect} from 'react';
import {isAuthenticated} from "../services/authService";
import { useNavigate, useLocation } from "react-router-dom";

const AuthComponent : FunctionComponent = () => {
    const isLogin = isAuthenticated();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('/signup')){
        }else if(!isLogin && (!location.pathname.includes('/login') || !location.pathname.includes('/signup') || !location.pathname.includes('/password-forgot'))) {
            navigate('/login')
        }
    }, [isLogin])

    return null;
};

export default AuthComponent;

