import React, { useEffect, useState, createContext } from 'react';
import {Route, Routes} from 'react-router-dom';
import LogIn from "../views/Login/LogIn";
import Header from "../components/shared/Header";
import ForgotPassword from "../views/ForgetPassword/ForgotPassword";
import SignUpComponent from "../views/Signup/SignUp";
import RecommendationCourses from "../views/CourseRecommendation";
import FilterPanels from "../views/FilterPanel/FilterPanels";
import CourseDetailComponent from "../views/CourseDetail";
import ProfileComponent from "../views/Profile/Profile";
import ManagementComponent from "../views/ManagementDashboard/ManagementDashboard";

import {CoursesSearch, CourseDetail, Home, Login, SignUp, ForgetPassword, Profile, Contact, ManagementDashboard} from "./constants";
import {getAllCourses, searchCourse} from "../services/courseService";
import {CourseType, ProviderProps} from "../model/course/courseInterface";
import ContactUsForm from "../components/ContactUsForm";
import AuthComponent from "../components/AuthComponent";
// import WithLoading from "../components/shared/WithLoading";

// const FilterPanelsWithLoading = WithLoading(FilterPanels)

const searchQueries = createContext<ProviderProps | null>(null);

const AppRoutes: React.FC = () => {
    const [search, setSearch] = useState<any>();
    const [courseList, setCourseList] = useState<CourseType[]>();
    const [searchList, setSearchList] = useState<CourseType[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchbarText, setSearchbarText] = useState("");



    const handleSearch = async (query: any, isClear: boolean = false) => {
        let q = isClear ? {price: {min:0, max: 5000}} : {...search, ...query};
        setSearch(q);
        try {
            setLoading(true);
            const courseList = await searchCourse(q);
            setSearchList(courseList);
            setLoading(false);
        } catch (e: any) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        const getCourseList = async () => {
            const courses: any = await getAllCourses();
            setCourseList(courses);
        }
        getCourseList();
    }, []);

    return (
        <>
            <AuthComponent />
            <searchQueries.Provider value={{handleSearch, searchbarText, setSearchbarText}}>
                <Header />
            </searchQueries.Provider>
            <Routes>
                <Route path={Home} element={<RecommendationCourses/>} />
                <Route path={Login} element={<LogIn/>}/>
                <Route path={ForgetPassword} element={<ForgotPassword/>}/>
                <Route path={SignUp} element={<SignUpComponent/>}/>
                <Route path={CoursesSearch} element={
                    <searchQueries.Provider value={{handleSearch, searchbarText, setSearchbarText}}>
                        <FilterPanels
                            courseList={searchList || courseList}
                            loading={loading}
                        />
                    </searchQueries.Provider>
                }/>
                <Route path={CourseDetail} element={<CourseDetailComponent/>}/>
                <Route path={Profile} element={<ProfileComponent /> } />
                <Route path={ManagementDashboard} element={<ManagementComponent /> } />
                <Route path={Contact} element={<ContactUsForm/> } />
            </Routes>
        </>
    );
};

export default AppRoutes;
export {searchQueries};
