import React from 'react';
import SearchCards from "../../components/SearchCards";
import WithLoading from "../../components/shared/WithLoading";
import Spinner from "../../components/shared/Spinner"

const SearchCardsWithLoading = WithLoading(SearchCards, Spinner)

const SearchCardsList = ({ courseList, loading }: any) => {
    return (
        <SearchCardsWithLoading
            isLoading={loading}
            cardsData={courseList}
        />
    );
};

export default React.memo(SearchCardsList);
