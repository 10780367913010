export const Login = "/login";
export const SignUp = "/signup";
export const Home = "/";
export const ResetPassword = "/reset-password";
export const ForgetPassword = "/password-forgot";
export const Courses = "/courses";
export const CoursesSearch = "/search";
export const CourseDetail = "/course/:id";
export const Profile = "/profile";
export const ManagementDashboard = "/management-dashboard";
export const Contact = "/contact";