import React, {useEffect, useState} from 'react';
import {TextInput, Button, Spinner} from "flowbite-react";
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import {isAuthenticated, sendPasswordResetLink} from "../../services/authService";
import {Home} from "../../routes/constants";

const ForgotPassword : React.FC = () => {

    const [loader, setLoader] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>("");

    const navigate = useNavigate();
    useEffect(() => {
        if (isAuthenticated()){
            navigate(Home);
        }
    }, []);
    const handleGetEmail = (e:any) => {
        setUserEmail(e.target.value)
    }

    const handleResetPasswordButton = async (e: any) => {
        e.preventDefault();
        if (userEmail){
            try {
                setLoader(true);
                await sendPasswordResetLink(userEmail);
                toast.success("Password reset link sent!")
                setLoader(false);
            } catch (e: any) {
                toast.error(e.message)
                setLoader(false);
            }
        } else {
            toast.warn("Please type correct Email!")
        }
    }

    return (
        <div className="md:container mx-auto">
            <div className="flex h-screen justify-center mt-20">
                <form className="flex flex-col gap-4 w-full lg:w-[30rem] lg:p-0 lg:pt-16 p-16">
                    <h1 className="text-center mb-2 font-bold">Wachtwoord vergeten?</h1>
                    <hr className="mb-5"/>

                    <div>
                        <TextInput
                            id="email1"
                            type="email"
                            placeholder="Email"
                            required={true}
                            value={userEmail}
                            onChange={handleGetEmail}
                            name="email"
                        />
                    </div>

                    <Button type="submit" color="dark" onClick={handleResetPasswordButton}>
                        {loader ? <Spinner aria-label="Default status example" /> : 'Herstel wachtwoord'}
                    </Button>
                    <div className="flex justify-center">
                        <p className="font-light ">of ga naar de</p>
                        <Link to="/login" className="ml-1 font-bold underline underline-offset-4" style={{color:"#7325A3"}}> login pagina</Link>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default React.memo(ForgotPassword);
