import React from 'react';
import {MdOutlineStarPurple500, MdOutlineStarOutline, MdOutlineStarHalf} from "react-icons/md";

interface PropsStar {
    reviews: number | string;
}

const Star = ({reviews}: PropsStar) => {

    const ratingStar = Array.from({length: 5}, (elem, idx:number) => {
        let number = idx + 0.5;

        return (
            <span key={idx}>
                {reviews >= idx + 1 ? <MdOutlineStarPurple500 className="text-[#e59819]" /> : reviews >= number ? <MdOutlineStarHalf className="text-[#e59819]" /> : <MdOutlineStarOutline className="text-[#e59819]" />}
            </span>
        )
    })
    return (
        <>
            <div className="flex items-center">
                {ratingStar}
            </div>
        </>
    );
};

export default Star;
