import HTTP, { beforeAPIRequest } from "./apiCommon";
import { API_PATH } from "../model/constants/apiPath";
import { doc, getDoc, collection, getDocs, query, where, documentId, QuerySnapshot, DocumentData, getCountFromServer } from "firebase/firestore";
import { db } from "./FirebaseService";
import { CourseList, CourseType, locationType } from "../model/course/courseInterface";
import { CollectionStrings } from "../model/constants/collectionNames";

// export const getCoursesByDocIds =  async (ids: Array<any>) => {
//     let courseData: CourseType[] = []
//     while (ids.length) {
//         const batch = ids.splice(0, 10);
//         console.log("batch", batch)
//         const q = query(collection(db, CollectionStrings.COURSES), where(documentId(), "in", batch));
//         const querySnapshot = await getDocs(q);
//         querySnapshot.forEach((doc) => {
//             // doc.data() is never undefined for query doc snapshots
//             // console.log('doc.data :: ', doc.data());
//             courseData.push({...doc.data(), id: doc.id} as CourseType);
//             console.log('doc.data :: ', courseData);
//         });
//     }
//
//     return courseData;
// }

export const getCoursesByDocIds = async (ids: Array<string>): Promise<CourseType[]> => {
    const courseData: CourseType[] = [];
    const idIndexMap: { [id: string]: number } = {};

    try {
        const queryPromises: Promise<QuerySnapshot<DocumentData>>[] = [];

        // Create a mapping of document ids with their index
        ids.forEach((id, index) => {
            idIndexMap[id] = index;
        });

        while (ids.length > 0) {
            const batch = ids.splice(0, 10);

            const queryPromise = getDocs(
                query(collection(db, CollectionStrings.COURSES), where(documentId(), "in", batch))
            );
            queryPromises.push(queryPromise);
        }

        const querySnapshots = await Promise.all(queryPromises);

        querySnapshots.forEach((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const course = {
                    ...doc.data(),
                    id: doc.id
                } as CourseType;

                // Get the index of the course based on the id
                const index = idIndexMap[course.id];
                if (index !== undefined) {
                    courseData[index] = course;
                }
            });
        });
    } catch (error) {
        console.error("Error fetching course data:", error);
        throw error;
    }

    return courseData;
};
export const getCourseRecommendationsList = async (userId: string | null) => {
    try {

        beforeAPIRequest();
        const res = await HTTP.post(API_PATH.RECOMMENDATION,
            {
                "User_UID": userId
            });
        const courseList: CourseList | any = {} as CourseList;
        for (let key in res.data?.body) {
            const courseData = await getCoursesByDocIds(res.data?.body[key]);
            courseList[key] = courseData
        }
        return courseList
    } catch (e: any) {
        console.log(e);
    }
}

export const getAllCourses = async () => {
    try {
        let courseData: CourseType[] = [];

        const q = query(collection(db, CollectionStrings.COURSES));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            courseData.push({ ...doc.data(), id: doc.id } as CourseType);
        });

        return courseData;
    } catch (e: any) {
        console.log(e.message);
    }
}

export const getcourseDates = async (org: string, id: string) => {
    beforeAPIRequest();
    try {
        const requestData = {
            organisation_edudux: org,
            program_id: id
        };
        const res = await HTTP.post(API_PATH.COURSEDAYS, requestData);
        return res?.data?.body
    } catch (error: any) {
        console.log(error.message)
    }
}

export const searchCourse = async (queryParams: any) => {
    try {
        if (window.location.pathname !== "/search/")
            return
        Object.keys(queryParams).forEach(key => {
            if (queryParams[key] === '') {
                delete queryParams[key];
            }
        })
        const res = await HTTP.post(API_PATH.FILTERING, queryParams);

        let courseData: CourseType[] = [];
        for (let key in res.data.body) {
            const searchData = await getCoursesByDocIds(res?.data.body[key]);
            courseData = searchData
        }

        return courseData;
    } catch (e: any) {
        console.log(e.message);
        return e;
    }
}
export const getCourseDetails = async (id: string | any) => {

    const docRef = doc(db, CollectionStrings.COURSES, id)
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return { ...docSnap.data(), id: docSnap.id };
    } else {
        return null;
    }
}

export const handleBookCourse = async (courseSubmitDetail: any) => {
    beforeAPIRequest();
    return await HTTP.post(API_PATH.COURSE_SUBMISSION, courseSubmitDetail);
}

export const getBookedCourse = async (userUid: string | null) => {
    const q = query(collection(db, CollectionStrings.ORDERS), where('User_UID', "==", userUid));
    const querySnapshot = await getDocs(q);
    let bookedList: any = [];
    querySnapshot.forEach((doc) => {
        bookedList.push(doc.data());
    });

    return bookedList;
}


export const getCourseLocationInFilterSection = async () => {
    const q = query(collection(db, CollectionStrings.COURSELOCATION))
    const querySnapshot = await getDocs(q)
    let locationList: locationType[] = []
    querySnapshot.forEach((doc) => {
        locationList.push({ ...doc.data() } as locationType)
    })
    return locationList
}

export const getCourseTypeAndCount = async () => {

    const q = query(collection(db, CollectionStrings.COURSES))
    const querySnapshot = await getDocs(q)
    let courseList: any = []
    let courseTypeAndCount: any = {};

    querySnapshot.forEach((doc) => {
        let currentDoc = doc.data();
        courseList.push(currentDoc);

        if (courseTypeAndCount.hasOwnProperty(currentDoc.type)) {
            courseTypeAndCount[currentDoc.type] += 1;
        } else {
            courseTypeAndCount[currentDoc.type] = 1;
        }
    });
    return courseTypeAndCount;
}