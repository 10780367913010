import axios from "axios";
import { API_PATH } from '../model/constants/apiPath';
axios.defaults.baseURL = API_PATH.BASE_PATH;

// interface CommonHeaderProperties extends HeadersDefaults {
//     "x-api-key": string;
//     // "Content-Type": string;
// }

export const beforeAPIRequest = () => {
    // (
    //     axios.defaults.headers! as unknown as Record<string, CommonHeaderProperties>
    // ).common["x-api-key"] = "golearn-api-key-we8sfn290"
    axios.defaults.headers.common["x-api-key"] = "golearn-api-key-we8sfn290";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    // (
    //     axios.defaults.headers! as unknown as Record<string, CommonHeaderProperties>
    // ).common["Content-Type"] = "application/json"

    // axios.interceptors.request.use(
    //     config => {
    //         config.headers['x-api-key'] = `Bearer ${localStorage.getItem('access_token')}`;
    //         config.headers['Content-Type'] = `Bearer ${localStorage.getItem('access_token')}`;
    //         return config;
    //     },
    //     error => {
    //         return Promise.reject(error);
    //     }
    // );
};


export default axios;