import {FireBaseDate} from "../services/dateTypes";
import moment from "moment";

export const handleDateFormat = (date: FireBaseDate | any): number | any => {
    if (date === null || date === undefined)
        return 0;
    const eventDate = moment(new Date(date.seconds * 1000 + date.nanoseconds * 1000 / 1000000).toDateString());
    const todaysDate = moment();

    var date1 = moment(todaysDate, 'DD-MM-YYYY');
    var date2 = moment(eventDate, 'DD-MM-YYYY');
    var months = date2.diff(date1, 'months');
    date1.add(months, 'months');
    var days = date2.diff(date1, 'days');

    if (months) {
        if (months > 1 && days > 0) {
            return months + ' ' + 'maanden'+ ' ' + days + (days < 2 ? " dag" : " dagen")
        } else {
            return months + ' ' + (months < 2 ? 'maand' : "maanden")
        }
    } else if (days > 1) {
        return days +" " + "dagen";
    } else {
        return days + " " + "dag"
    }
}
