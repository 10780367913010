import React from 'react';
import CourseDetailComponent from "../components/CourseDetailComponent";

const CourseDetail = () => {
    return (
        <div className="md:mt-12 mt-2">
            <CourseDetailComponent />
        </div>
    );
};

export default CourseDetail;
