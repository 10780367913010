import React from 'react';
import Slider from "react-slick";
import {CourseType} from "../../model/course/courseInterface";
import Star from "./Star";

interface CourseListProp {
    courseList: CourseType[];
    handleOpenModal: void | any;
}

const CardSlider = ({courseList, handleOpenModal}:CourseListProp) => {
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <div  className="flex items-center justify-center">
                <div className="max-w-screen-2xl py-2 w-full px-0">
                    <div>
                        <Slider {...settings}>
                                {courseList?.map((card, idx) => (
                                    <div className="flex-1 cursor-pointer" key={idx} onClick={() => handleOpenModal(card)}>
                                        <div className="sm:mr-2 p-2 border border-inherit text-primary font-roboto">
                                            <div className="h-36">
                                                <img src={card?.image || "https://img-b.udemycdn.com/course/240x135/394676_ce3d_5.jpg"} alt="card-banner" className="h-full w-full object-cover"/>
                                            </div>
                                            <div className="py-2.5 flex flex-col">
                                                <h3 className="font-extrabold min-h-[2.4rem] mb-1 text-base tracking-tight text-primary dark:text-white leading-tight line-clamp-2">
                                                    {card?.name}
                                                </h3>
                                                <p className="text-xs flex justify-between items-center mb-1 text-[#6A6F73]">
                                                    {card?.trainer}
                                                </p>
                                                <div className="flex items-center mb-1">
                                                 <span className="mr-1 tracking-tight text-sm font-bold text-[#b4690e]">
                                                    {card?.review_score ? Math.round(parseFloat(card?.review_score)) || Math.round(8.7) : ""}
                                                </span>
                                                    <Star reviews={card?.review_score?.toString().replace(',', '.')}/>
                                                    <span className="text-[0.7rem] ml-1 font-normal text-[#6a6f73] pt-[3px]">{card?.number_of_reviews && "("} {card?.number_of_reviews}{card?.number_of_reviews && ")"}</span>
                                                </div>
                                                <div className="text-primary dark:text-gray-400 mb-1">
                                                <span className="font-semibold text-base text-primary">
                                                        €{card?.price}
                                                </span>
                                                </div>
                                                {/*<div className="mb-1">*/}
                                                {/*    <span className="bg-[#eceb98] text-primary font-bold text-[11px] px-[7px] py-[4px] tracking-tight">Bestseller</span>*/}
                                                {/*</div>*/}
                                                <div className="flex items-center">
                                                    {/*<span className="">*/}
                                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#6a6f73]">*/}
                                                    {/*        <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"/>*/}
                                                    {/*    </svg>*/}
                                                    {/*</span>*/}
                                                    {
                                                        card?.type === "offline" ?
                                                            <div className="flex items-center">
                                                            <span className="pt-1 text-xs ml-[2px] font-normal">
                                                                {card?.startdates[1].location}
                                                            </span>
                                                            {/*    <span className="text-[20px] stroke-1 text-[#6A6F73] -rotate-90  ml-4 font-normal ">*/}
                                                            {/*    <IoNavigate />*/}
                                                            {/*</span>*/}
                                                            {/*    <span className="text-xs pt-1 ml-1 font-normal">*/}
                                                            {/*    {card.startdates[1].spots_left} KM*/}
                                                            {/*</span>*/}
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSlider;