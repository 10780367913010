import HTTP, { beforeAPIRequest } from "./apiCommon";
import { API_PATH } from "../model/constants/apiPath";

export const getDashboardData = async (managerEmail: string | null) => {
    try {

        beforeAPIRequest();
        const res = await HTTP.post(API_PATH.MANAGEMENT,
            {
                "managerEmail": managerEmail
            });
        // const courseList: CourseList | any = {} as CourseList;
        // for (let key in res.data) {
        //     const courseData = await getCoursesByDocIds(res.data[key]);
        //     courseList[key] = courseData
        // }
        return (res?.data)
    } catch (e: any) {
        console.log(e.message);
    }
}