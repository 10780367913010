import React, { useState } from 'react';
import {useNavigate} from "react-router-dom"
import Star from "./shared/Star";
import CourseDetailComponent from "./CourseDetailComponent";
import Modal from "react-modal";

const SearchCards = ({cardsData}: any) => {
    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
    const [selectedCourse, setSelectedCourse] = useState<any>({})

    const handleOpenModal = (currentCourse: any) => {
        if (window.innerWidth < 1040) {
            navigate(`/course/${currentCourse.id}`)
            setSelectedCourse(currentCourse)
        } else {
            setModalIsOpen(!modalIsOpen)
            setSelectedCourse(currentCourse)
        }

    }

    const customStyles = {
        content: {
            width: "85%",
            paddingTop:"40px",
            paddingBottom:"40px",
            paddingLeft:"30px",
            paddingRight:"30px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const closeModal = () => {
        setModalIsOpen(false)
    }
    return (
        <div className="flex flex-col gap-4 w-full">
            {
                cardsData && cardsData?.length > 0 && cardsData?.map((card:any, idx:number) => (
                    <div className="border-b border-gray-300" key={idx} onClick={() => handleOpenModal(card)}>
                        <div className="flex justify-between mb-4 cursor-pointer">
                            <div className="flex">
                                <div className="mr-4">
                                    <img src={card?.image || "https://img-b.udemycdn.com/course/240x135/394676_ce3d_5.jpg"} alt="course-banner" className="sm:min-w-[262px] sm:w-[262px] sm:h-[155px] min-w-[65px] w-[65px] h-[55px]" />
                                </div>
                                <div className="">
                                    <h3 className="sm:mb-1 mb-0 text-medium sm:text-lg tracking-tight text-primary dark:text-white leading-tight font-extrabold">{card.name}</h3>
                                    <p className="sm:block hidden lg:max-w-xl md:max-w-[400px] max-w-[250px] my-1 sm:text-base text-sm text-primary leading-tight dark:text-gray-400 font-normal">{card.description_small}</p>
                                    <p className="text-xs sm:my-1 mb-0.5 text-[#6a6f73]">{card.trainer}</p>
                                    <div className="flex items-center sm:mb-1 mb-0.5">
                                                 <span className="mr-1 tracking-tight text-sm font-extrabold text-[#b4690e]">
                                                    {card?.review_score ? Math.round(card?.review_score) : ""}
                                                </span>
                                        <Star reviews={card?.review_score}/>
                                        <span className="text-[0.7rem] ml-1 font-normal text-[#6a6f73] pt-[3px]">{card?.number_of_reviews && "("}{card?.number_of_reviews}{card?.number_of_reviews && ")"}</span>
                                    </div>
                                    <div className="sm:hidden block">
                                        <div className="flex text-right">
                                            <div className="mb-0.5 flex items-center">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#6a6f73]">
                                                            <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"/>
                                                        </svg>
                                                    </span>
                                                {
                                                    card?.type === "offline" ?
                                                        <div className="flex  items-center">
                                                            <span className="pt-1 text-xs ml-[2px] font-normal">{card?.startdates[1].location}</span>
                                                            {/*<span className="text-[14px] stroke-1 text-[#6A6F73] -rotate-90  ml-4 font-normal "><IoNavigate /></span>*/}
                                                            {/*<span className="text-xs pt-1 ml-1 font-normal">{card.startdates[1].spots_left} KM </span>*/}
                                                        </div>
                                                        :
                                                        <span className="text-xs pt-1 ml-[2px] font-normal">Online course</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <p className="font-semibold text-sm text-primary block sm:hidden"> €{card?.price}</p>
                                    {/*<p className="text-xs mt-1 mb-2 flex justify-start">*/}
                                        {/*<span className="!font-medium">{card.type}</span>*/}
                                        {/*<span className="ml-2 text-gray-500">-</span>*/}
                                        {/*<span className="ml-2 text-gray-500">{card.distance}</span>*/}
                                        {/*<span className="ml-2 text-gray-500">-</span>*/}
                                        {/*<span className="ml-2 text-gray-500" >{card.entry_level}</span>*/}
                                    {/*</p>*/}
                                    {/*<span className="bg-[#eceb98] text-[#3d3c0a] font-semibold text-xs px-[7px] py-[4px] tracking-tight">Bestseller</span>*/}
                                </div>
                            </div>
                            <div className="sm:block hidden">
                                <div className="flex flex-col text-right">
                                    <span className="font-semibold text-base text-primary"> €{card?.price}</span>
                                    <div className="my-1 flex items-center">
                                        {
                                            card?.type === "offline" ?
                                                <div className="flex xl:flex-row flex-col  items-center">
                                                    <p className="flex items-center">
                                                        <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-[#6a6f73]">
                                                            <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"/>
                                                        </svg>
                                                    </span>
                                                        <span className=" pt-1 text-xs ml-[2px] font-normal">{card?.startdates[1].location}</span>
                                                    </p>
                                                    <p className="flex items-center">
                                                        {/*<span className="text-[16px] stroke-1 text-[#6A6F73] -rotate-90 font-normal xl:ml-3"><IoNavigate /></span>*/}
                                                        {/*<span className="text-xs pt-1 ml-0.5 font-normal">{card.startdates[1].spots_left} KM </span>*/}
                                                    </p>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <svg className="w-6 h-6 cursor-pointer float-right" fill="none" stroke="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={closeModal}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
                <CourseDetailComponent course={selectedCourse} />
            </Modal>
        </div>
    );
};

export default React.memo(SearchCards);
