import React,{ useState } from 'react';
import {Button, Label, TextInput, Checkbox, Spinner} from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logInWithEmailAndPassword } from "../../services/FirebaseService";
import { Home } from '../../routes/constants';

interface UserData {
    email: string;
    password: string
}

const SigninForm: React.FC = () => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState<boolean>(false);
    const [loginError, setLoginError] = useState('');
    const [userDetails, setUserDetails] = useState<UserData>({
        email: "",
        password: ""
    });

    const addData = (e:any) => {
        setLoginError('')
        const {name, value} = e.target;
        setUserDetails(() => {
            return{
                ...userDetails,
                [name]: value
            }
        })
    }

    const handleUserLogin = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        setLoader(true);
        const {email,password} = userDetails
        setUserDetails({...userDetails, email: email, password: password})
        if (email && password) {
            try {
                const res = await logInWithEmailAndPassword(email, password);

                if (res?.user && res?.user?.uid) {
                    // console.log("res ::", res.user)
                    navigate(Home);
                    setLoader(false);
                    toast.success("Login succesvol")
                } else{
                    setLoader(false);
                    setLoginError('Het e-mailadres of wachtwoord klopt niet.')
                }

            } catch (e) {
                setLoader(false)
                toast.warn("Login niet gelukt")
            }
        }


    }

    return (
        <div className="">
            <div className="md:container mx-auto">
                <div className=" flex justify-center items-center h-[65vh]">
                    <form className="flex flex-col gap-4 w-full lg:w-[30rem] lg:p-0 p-[5rem]" onSubmit={handleUserLogin}>
                        <h3 className="text-center mb-2 text-xl font-bold">Log in to Ace Acedemy</h3>
                        <hr className="mb-5"/>
                        <div>
                            <TextInput
                                id="email1"
                                type="email"
                                value={userDetails.email}
                                placeholder="Email"
                                required={true}
                                name="email"
                                onChange={addData}
                            />
                        </div>
                        <div>
                            <TextInput
                                id="password"
                                type="password"
                                value={userDetails.password}
                                required={true}
                                placeholder="Wachtwoord"
                                name="password"
                                onChange={addData}
                            />
                        </div>
                        <div className="flex gap-2 items-center">
                            <Checkbox id="remember" />
                            <Label htmlFor="remember">
                                Onthoud mij
                            </Label>
                        </div>
                        <Button type="submit" color="dark">
                            {
                               loader ? <Spinner aria-label="Default status example" /> : "Log In"
                            }
                        </Button>
                        {loginError && <p className="text-red-700 text-md">{loginError}</p>}
                        <Link to="/password-forgot">
                            <p className="flex justify-center"> of <span className="ml-1 text-[#7325A3]"> herstel wachtwoord </span></p>
                        </Link>
                        {/*<div className="flex justify-center">*/}
                        {/*    <p className="font-normal text-sm">Don't have an account?*/}
                        {/*        <span> <Link to="/signup" className=" underline text-base text-[#7325A3] font-bold"> Sign Up</Link></span>*/}
                        {/*    </p>*/}

                        {/*</div>*/}
                    </form>
                </div>
            </div>
        </div>

    );
};

export default SigninForm;
