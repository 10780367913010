
export interface UserType {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    title: string | null;
    company: string | null;
    image?: string | null;
    budget: string | null;
    budget_exp_date_yyyy_mm_dd: any;
    functionName: string | null;
    education: string | null;
    gender: string | null;
    role: string | null;
    managerEmail: string | null;
    hardskills: any;
    softskills: any;
    profileCompleted: string | null;

}
export const initializeUser: UserType = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    title: null,
    company: null,
    image: null,
    budget: null,
    budget_exp_date_yyyy_mm_dd: null,
    functionName: null,
    education: null,
    gender: null,
    role: null,
    managerEmail: null,
    hardskills: null,
    softskills: null,
    profileCompleted: null,
}

export interface contactUsDetail {
    fullName: string | null;
    email: string | null;
    message: string | null;
}