import React from 'react';
import MultiRangeSlider from "./MultiRangeSlider";

const PriceSlider = ({ minValue, maxValue, setMinValue, setMaxValue }: any) => {
    return (
        <div>
            <div className="price-input w-full flex mb-4">
                <div className="field h-[45px] w-[55%] flex items-center">
                    <span>Min</span>
                    <span className="ml-2">€</span>
                    <input
                        className="input-min h-[24px] p-0 w-full outline-none rounded-md text-base border-0"
                        type="number"
                        value={minValue}
                        name="min"
                        readOnly
                    />
                </div>

                <div className="separator w-[50px] flex justify-center items-center text-base">-</div>

                <div className="field h-[45px] w-full flex items-center">
                    <span>Max</span>
                    <span className="ml-2">€</span>
                    <input
                        className="input-max h-[24px] p-0 w-full outline-none rounded-md text-base border-0"
                        type="number"
                        value={maxValue}
                        name="max"
                        readOnly
                    />
                </div>
            </div>
            <div>
                <MultiRangeSlider
                    minValue={minValue}
                    maxValue={maxValue}
                    min={0}
                    max={5000}
                    setMinValue={setMinValue}
                    setMaxValue={setMaxValue}
                    onChange={({ min, max }: { min: number; max: number }) =>{
                        if (typeof setMinValue === 'function'){
                            setMinValue(min)
                        }
                        if (typeof setMaxValue === 'function'){
                            setMaxValue(max)
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default PriceSlider;
