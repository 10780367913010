export const dropdownData = {
    profileHardSkills :[
        {
            name: "Microsoft Azure"
        },{
            name: "Google Cloud Platform"
        },{
            name: "IBM Cloud"
        },{
            name: " Oracle Cloud"
        },{
            name: "DigitalOcean"
        },{
            name: "Heroku"
        },{
            name: "Kubernetes"
        },{
            name: "Apache Mesos"
        },{
            name: "CoreOS"
        },{
            name: "Docker Swarm"
        },{
            name: "Red Hat OpenShift"
        },

    ],
    profileSoftSkills :[
        {
            name: "Critical thinking"
        },{
            name: "Time management"
        },{
            name: " Problem-solving"
        },{
            name: "Leadership"
        },{
            name: " Attention to detail"
        },{
            name: "Project management"
        },{
            name: "Teamwork"
        },{
            name: "Adaptability"
        },{
            name: "Creativity"
        },{
            name: " Interpersonal skills"
        },{
            name: "Decision making"
        },{
            name: " Conflict resolution"
        }

    ],


    list :[
        {
            name: "Business",
        },{
            name: "Finance & Accounting",
        },{
            name: "It & Software",
        },{
            name: "Office Productivity",
        },{
            name: "Personal Development",
        },{
            name: "Design",
        },{
            name: "Marketing",
        },{
            name: "Communicatie & Presenteren",
        },{
            name: "Management",
        },{
            name: "Teaching & Academics",
        }
    ]
}