import React, {useEffect, useState} from 'react';
import DataCard from '../../components/datacard';
import * as indicators from '../../data/indicators';
import axios from "axios";
import Chart from '../../components/chart';
import Table from "../../components/DataTable";
import './ManagementDashboard.css';
import {getUser} from "../../services/userService";
import {getDashboardData} from "../../services/dashboardService";
import {getAllCourses} from "../../services/courseService";
import EmployeeDashboard from "../../components/EmployeeDashboard";
import BookingDashboard from "../../components/BookingDashboard";

const ManagementPlatform: React.FC = () => {
    const [step, setStep] = useState(0)
    const [isLoadingManagement, setIsLoadingManagement] = useState(false)
    const [employeeData, setEmployeeData] = useState([])
    const [bookingData, setBookingData] = useState([])

    const DashboardData = async () => {
        try {
            setIsLoadingManagement(true)
            const user = await getUser();
            const dashData = await getDashboardData(user.managerEmail);

            const bookingData = JSON.parse(dashData?.bookings_table);
            const employeeData = JSON.parse(dashData?.budget_per_employee);

            setBookingData(bookingData);
            setEmployeeData(employeeData);
            setIsLoadingManagement(false)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        DashboardData();
    }, []);
    return (
        <div className="flex">
            <div className=" mr-3 !w-[245px] min-w-[245px] lg:block hidden h-[calc(100vh-60px)] overflow-y-auto shadow-md mt-1">
                <div className="mt-28 mx-3">
                    <p className={`${step === 0 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} flex items-center border text-md font-semibold cursor-pointer px-[0.8rem] py-2 hover:opacity-70 `}
                    onClick={() => {
                       setStep(0)
                    }}>
                        Employees
                    </p>
                    <p className={`${step === 1 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} mt-2 flex items-center border text-md font-semibold cursor-pointer px-[0.8rem] py-2 hover:opacity-70`}
                       onClick={() => {
                           setStep(1)
                       }}>
                        Bookings
                    </p>
                    <p className={`${step === 2 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} mt-2 flex items-center border text-md font-semibold cursor-pointer px-[0.8rem] py-2 hover:opacity-70 `}
                       onClick={() => {
                           setStep(2)
                       }}>
                        Invoices
                    </p>
                </div>
            </div>
            <div className= "management-sidebar w-full lg:mx-3 h-[calc(100vh-75px)] overflow-y-auto mt-4" >
                <div className=" mr-3 w-full lg:hidden block overflow-y-auto mt-1">
                    <div className="mt-4 mx-3">
                        <p className={`${step === 0 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} flex items-center border  text-md font-semibold cursor-pointer px-[0.8rem] py-2 `}
                           onClick={() => {
                               setStep(0)
                           }}>
                            Employees
                        </p>
                        <p className={`${step === 1 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} mt-2 flex items-center border text-md font-semibold cursor-pointer px-[0.8rem] py-2 `}
                           onClick={() => {
                               setStep(1)
                           }}>
                            Bookings
                        </p>
                        <p className={`${step === 2 ? 'bg-blue-600 text-white border-transparent' : 'text-primary border-black'} mt-2 flex items-center border text-md font-semibold cursor-pointer px-[0.8rem] py-2 `}
                           onClick={() => {
                               setStep(2)
                           }}>
                            Invoices
                        </p>
                    </div>
                </div>
                <div className="mt-10 px-5 justify-center">
                    <h1 className="mt-3 mb-3 text-2xl font-roboto font-extrabold">Management Dashboard</h1>
                </div>

                {step === 0 &&
                <EmployeeDashboard employeeData={employeeData} isLoadingManagement={isLoadingManagement}/>}

                {step === 1 &&
                <BookingDashboard bookingData={bookingData}/>}

                {step === 2 &&
                <div className="">

                    <div className="block md:grid md:grid-rows-1 md:grid-flow-col gap-4">
                        {indicators.data.map((data, index) => {
                            return <DataCard key={index} {...data} />
                        })}
                    </div>
                    <div
                        className="basis-0 flex flex-grow flex-shrink-0 flex-col flex-nowrap rounded-xl justify-center bg-white mob:p-10 py-5 m-2">

                        <Chart/>


                        <Table/>
                    </div>
                </div>
                }
            </div>
        </div>
        

    );
};

export default React.memo(ManagementPlatform);
