import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import { CourseType } from "../model/course/courseInterface";
import CourseDetailComponent from "./CourseDetailComponent";
import SectionUpperView from "./SectionUpperView";
import CardSlider from "./shared/CardSlider";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getUser} from "./../services/userService";
import {initializeUser, UserType} from "./../model/user/userInterface";

interface CourseListProp {
    courseList: CourseType[]
}
const customStyles = {
    content: {
        width: "85%",
        paddingTop:"40px",
        paddingBottom:"40px",
        paddingLeft:"30px",
        paddingRight:"30px",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const RecommendationCards = ({ courseList }: CourseListProp | any) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetail, setUserDetail] = useState<UserType>(initializeUser);
    const [selectedCourse, setSelectedCourse] = useState<any>({})
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
    const [listOpen, setListOpen] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const handleOpenModal = (currentCourse: any) => {
        if (window.innerWidth < 955) {
            navigate(`/course/${currentCourse.id}`)
            setSelectedCourse(currentCourse)
        } else {
            setModalIsOpen(!modalIsOpen)
            setSelectedCourse(currentCourse)
        }

    }

    const handleSearch = (e:ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
        setListOpen(true)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (searchText.length)
            handleMakeQuery("q", searchText);
        setListOpen(false);
        inputRef.current?.blur();
    }

    const handleQuery = () => {
        handleMakeQuery("q", searchText);
        setListOpen(false);
        inputRef.current?.blur();
    };

    const handleMakeQuery = (name: string, value: string) => {
        let testQuery:any = {};
        for (const [key, value] of searchParams){
            testQuery[key] = value
        }
        testQuery[name] = value
        let searchQuery = '/search/?'
        for (const property in testQuery) {
            searchQuery = searchQuery + `&${property}=${testQuery[property]}`
        }
        navigate(searchQuery);
        window.scrollTo({top:0, behavior: 'smooth'});
    };

    useEffect(() => {
        if(modalIsOpen){
            document.body.style.overflow = "hidden";
        }
        if(!modalIsOpen){
            document.body.style.overflow = "unset";
        }
    }, [modalIsOpen]);


    
    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        if(window.location.pathname === '/'){
            setSearchText("");
        }
    }, [location.pathname])

    useEffect(() => {
        let handler = () => {
            setListOpen(false);
        }
        window.addEventListener("click", handler)
    }, );


    const handleRerouteToProfile = async ()=> {
        const user = await getUser();
        await setUserDetail(user);
        if (user?.profileCompleted === "undefined"){
            navigate("/profile");
        }
    }
    useEffect(() => {
        handleRerouteToProfile()
      }, []);

    return (
        <div className="md:container md:mx-auto mb-2">
            <div className="mb-4">
                <SectionUpperView/>
            </div>
            <div className="">
                <div className="border border-gray-200 sm:p-6 p-2 pb-0 font-roboto text-primary">
                    <div className="sm:pl-3 pl-2 sm:pr-0 pr-2">
                        <div className="block">
                            <h1 className="text-2xl font-normal mb-2">Vind de cursus die bij jouw past!</h1>
                            {/* <p className="lg:w-[61%] mb-1 font-normal text-base">
                                Popular courses from your peers can be found below. Looking for something specific? Use our quick search and filter to find the training
                                that suits your needs.
                            </p> */}
                            {/* <p className="lg:w-[61%] mb-1 font-normal text-base">
                                Vind de cursus die bij jouw past!
                            </p> */}
                            {/* <p className="font-normal text-base mb-2">Happy learning!</p> */}
                            <form onSubmit={handleSubmit}>
                                <div className="autocomplete wrapper relative w-full md:w-96">
                                    <div className="flex relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <input type="text" id="simple-search" placeholder="Zoek opleidingen" autoComplete="off" required
                                           className="border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-gray-400 focus:border-gray-400 block w-full pl-10 p-2.5"
                                           value={searchText} onChange={handleSearch} ref={inputRef}
                                    />
                                    </div>
                                    { (listOpen && searchText) &&
                                        <ul className="absolute list-none p-[unset] m-[unset] w-full mt-1">
                                            <li className="bg-white border border-gray-300 flex gap-3 items-center shadow-md px-3 py-2 cursor-pointer" onClick={handleQuery}>
                                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                                </svg>
                                                <span>{searchText}</span>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    <h3 className="mt-3 sm:pl-3 pl-2  text-2xl font-roboto font-extrabold">Tools binnen uw vakgebied</h3>
                    <CardSlider courseList={courseList?.hand_picked} handleOpenModal={handleOpenModal}/>
                </div>
                <div className="border border-t-0 border-gray-200 sm:px-6 px-2">
                    <h3 className="pt-3 sm:pl-3 pl-2 text-2xl font-roboto font-extrabold">Beste keuzes binnen uw vakgebied</h3>
                    <CardSlider courseList={courseList?.hardskills} handleOpenModal={handleOpenModal}/>
                </div>
                <div className="border border-t-0 border-gray-200 sm:px-6 px-2">
                    <h3 className="pt-3 sm:pl-3 pl-2 text-2xl font-roboto font-extrabold">Persoonlijke ontwikkeling</h3>
                    <CardSlider courseList={courseList?.softskills} handleOpenModal={handleOpenModal}/>
                </div>
                <div className="border border-t-0 border-gray-200 sm:px-6 px-2">
                    <h3 className="pt-3 sm:pl-3 pl-2 text-2xl font-roboto font-extrabold">Populaire trainingen</h3>
                    <CardSlider courseList={courseList?.populair} handleOpenModal={handleOpenModal}/>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                onRequestClose={closeModal}
                ariaHideApp={false}
            >
                <svg className="w-6 h-6 cursor-pointer float-right" fill="none" stroke="red" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={closeModal}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
                <CourseDetailComponent course={selectedCourse} />
            </Modal>
        </div>
    );
};
export default React.memo(RecommendationCards);
