import {db, logout } from "./FirebaseService";
import {collection, addDoc, query, where, documentId, getDocs} from "firebase/firestore"
import { UserType, initializeUser } from '../model/user/userInterface';
import {CollectionStrings} from "../model/constants/collectionNames";

export const getUser =  async (uid: string | null = null) => {
    // console.log("uid :::", uid )
    let budget = sessionStorage.getItem('budget_exp_date_yyyy_mm_dd') &&
    sessionStorage.getItem('budget_exp_date_yyyy_mm_dd') !== undefined &&
    sessionStorage.getItem('budget_exp_date_yyyy_mm_dd') !== '' &&
    sessionStorage.getItem('budget_exp_date_yyyy_mm_dd') !== 'undefined' ?
        JSON.parse(sessionStorage.getItem('budget_exp_date_yyyy_mm_dd') as any)
        : null
    const user: UserType = {
        ...initializeUser,
        id: sessionStorage.getItem("id") || uid, // .getItem('id') || uid,
        email: sessionStorage.getItem('email'),
        firstName: sessionStorage.getItem('firstName'),
        lastName: sessionStorage.getItem('lastName'),
        company: sessionStorage.getItem('company'),
        title: sessionStorage.getItem('title'),
        education: sessionStorage.getItem('education'),
        budget: sessionStorage.getItem('budget'),
        gender: sessionStorage.getItem('gender'),
        functionName: sessionStorage.getItem('functionName'),
        role: sessionStorage.getItem('role'),
        managerEmail: sessionStorage.getItem('managerEmail'),
        hardskills: sessionStorage.getItem('hardskills'),
        softskills: sessionStorage.getItem('softskills'),
        profileCompleted: sessionStorage.getItem('profileCompleted'),
        budget_exp_date_yyyy_mm_dd: budget
    }

    return user as UserType;
}

export const setUser = async (data: any, id: any) => {
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('firstName', data?.firstname || data?.firstName);
    sessionStorage.setItem('lastName', data?.lastname || data?.lastName);
    sessionStorage.setItem('education', data?.education);
    sessionStorage.setItem('email', data?.email);
    const functionName = typeof data?.functionName !== 'undefined' ? data?.functionName : '';  // Conditional (ternary) operator
    sessionStorage.setItem('functionName', functionName);
    sessionStorage.setItem('title', data?.title);
    sessionStorage.setItem('company', data?.company);
    sessionStorage.setItem('budget', data?.budget);
    sessionStorage.setItem('gender', data?.gender);
    sessionStorage.setItem('managerEmail', data?.managerEmail);
    sessionStorage.setItem('role', data?.role);
    sessionStorage.setItem('hardskills', data?.hardskills);
    sessionStorage.setItem('softskills', data?.softskills);
    sessionStorage.setItem('profileCompleted', data?.profileCompleted);
    sessionStorage.setItem('budget_exp_date_yyyy_mm_dd', JSON.stringify(data?.budget_exp_date_yyyy_mm_dd));
}


export const updateAndCreateUser = async (user: UserType) => {
    await addDoc(collection(db, CollectionStrings.EMPLOYEES), {
        id: user.id,
        firstName: user.firstName,
        authProvider: "local",
        email: user.email,
    });
}

export const getUserByUID = async (uid: string | any) => {
    const q = query(collection(db, CollectionStrings.EMPLOYEES), where(documentId(), "==", uid));
    const querySnapshot = await getDocs(q);
    let user: any = {};
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        user = doc.data();
    });
    return user;
}

export const getUserByEmail = async (email: string | any) => {
    const q = query(collection(db, CollectionStrings.EMPLOYEES), where('email', "in", [email, email?.charAt(0).toUpperCase() + email?.slice(1)]));
    const querySnapshot = await getDocs(q);
    let user: any = {};
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        user = doc.data();
    });
    return user;
}

export const handleLogout = async () => {
    logout();
    sessionStorage.clear();
}