import { CurrencyDollarIcon } from '@heroicons/react/outline'
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Gebruikers op het platform                ",
      data: [33, 53, 85, 41, 44, 65],
      fill: false,
    //   backgroundColor: "rgba(0, 217, 84,0.2)",
      backgroundColor: "rgba(0, 217, 84,1)",
      borderColor: "rgba(0, 217, 84,1)",
      hoverBorderColor: "rgba(0, 217, 84,0.2)",
    },
    {
      label: "Gekochte cursussen",
      data: [10, 2, 35, 12, 19, 76],
      fill: false,
      borderColor: "rgba(0, 0, 255)",
      backgroundColor: "rgba(0, 0, 255)",
      hoverBorderColor: "rgba(0, 217, 84,0.2)",
    }
  ]
};

const Chart = () => {

  return (
    <div className="w-[90%] mt-5">
      <span className="flex flex-row items-center ml-2 mb-2 font-bold text-[14px] text-oceanblue">
        {/* <CurrencyDollarIcon className="w-5 h-5 mr-2" color="rgba(0, 217, 84,1)" /> */}
        <h3 className="font-extrabold min-h-[2.4rem] mb-1 text-base tracking-tight text-primary dark:text-white leading-tight line-clamp-2">
          Afgenomen aantal cursussen en geregistreerde gebruikers
        </h3>
      </span>

      <div className='justify-center'>
          <Line data={data} />
        </div>  
    </div>
  )
}

export default Chart;