import React, {useEffect} from 'react';
import SigninForm from "../../components/auth/signin-form";
import { isAuthenticated } from "../../services/authService";
import { Home } from '../../routes/constants';
import { useNavigate } from 'react-router-dom';

const LogIn: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated()){
            navigate(Home);
        }
    }, []);

    return (<div><SigninForm/></div>);
};

export default React.memo(LogIn);
