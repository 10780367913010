import React, {useEffect, useState} from 'react';
import RecommendationCards from "../../components/RecommendationCards";
import {CourseType} from "../../model/course/courseInterface";
import {getCourseRecommendationsList} from "../../services/courseService";
import CardSkeleton from "../../CardSkeleton";
import { getUser } from "../../services/userService";
import WithLoading from "../../components/shared/WithLoading";

const RecommendationCardsWithLoading = WithLoading(RecommendationCards, CardSkeleton)

const Index = () => {

    const [courseList, setCourseList] = useState<CourseType[]>();
    const [loading, setLoading] = useState<boolean>(true);

    const getCourseList = async () => {
        const user = await getUser();
        const courses: any = await getCourseRecommendationsList(user.id);
        setCourseList(courses);
        setLoading(false);
    }

    useEffect(() => {
        getCourseList();
    }, []);


    return (
            <RecommendationCardsWithLoading
                isLoading = {loading}
                courseList = {courseList}
            />
    );
};

export default React.memo(Index);
