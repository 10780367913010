import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, TextInput} from "flowbite-react";
import {getUser} from "../../services/userService";
import {updateProfile} from "../../services/FirebaseService";
import {initializeUser, UserType} from "../../model/user/userInterface";
import {toast} from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import './Profile.css';
import {dropdownData} from "../../components/DropdownData";

interface FormValues {
    education: string;
    gender: string;
  }
  const customStyles = {
    content: {
        width: "85%",
        paddingTop:"40px",
        paddingBottom:"40px",
        paddingLeft:"30px",
        paddingRight:"30px",
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
interface ListHardSkillsData {
    name:string;
}
const HardSkillsList: ListHardSkillsData[] = dropdownData.profileHardSkills;
interface ListSoftSkillsData {
    name:string;
}
const SoftSkillsList: ListSoftSkillsData[] = dropdownData.profileSoftSkills;

const Profile: React.FC = () => {
    const navigate = useNavigate();
    const [userDetail, setUserDetail] = useState<UserType>(initializeUser);
    const [hardskills, setHardskill] =useState<string[]>([]);
    const [softskills, setSoftskill] =useState<string[]>([]);
    const [showHardSkillList, setShowHardSkillList] = useState(false);
    const [showSoftSkillList, setShowSoftSkillList] = useState(false);
    const [searchHardSkill, setSearchHardSkill] = useState('');
    const [searchSoftSkill, setSearchSoftSkill] = useState('');
    const [firstName, setFirstName] = useState<any>('');
    const [lastName, setLastName] = useState<any>('');
    const [education, setEducation] = useState<any>('');
    const [qualification, setQualification] = useState<any>('');
    const [formValues, setFormValues] = useState<FormValues>({
        education: '',
        gender: '',
      });
    // function mouseOver(){
    //     setShowList(true);
    // }
    // function mouseOut(){
    //     setShowList(false);
    // }
    useEffect(() => {
        handleGetUser();       
        
    }, []);

      
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            gender: event.target.value,
          });
        setSearchSoftSkill('');
        setSearchHardSkill('');
        };
    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };
    const handleEducatChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEducation(event.target.value);
    };
    const handleQualificationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setQualification(event.target.value);
    };

    const handleEducationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFormValues({
          ...formValues,
          education: event.target.value,
        });
      };

    const handleSearchSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'searchHardSkills') {
            setSearchHardSkill(value);
            setSearchSoftSkill('');
        } else if (name === 'searchSoftSkills') {
            setSearchSoftSkill(value);
            setSearchHardSkill('');
        }
    };

    const handleHardskillChange = (hardskills:any) => {
        const arrayWithoutEmpty: any = hardskills.filter((item: string) => item !== '');
        const arrayWithoutUndefined: any = arrayWithoutEmpty.filter((item: string) => item !== 'undefined');
        setHardskill(arrayWithoutUndefined)
      }
    const addHardSkill = (newSkill: string) => {
        setHardskill(prevSkills => {
            if (!prevSkills.includes(newSkill)) {
                return [...prevSkills, newSkill];
            }
            return prevSkills;
        });
    }
    const addSoftSkill = (newSkill: string) => {
        setSoftskill(prevSkills => {
            if (!prevSkills.includes(newSkill)) {
                return [...prevSkills, newSkill];
            }
            return prevSkills;
        });
    }

      const handleSoftskillChange = (softskills:any) => {
        const arrayWithoutEmpty: any = softskills.filter((item: string) => item !== '');
        const arrayWithoutUndefined: any = arrayWithoutEmpty.filter((item: string) => item !== 'undefined');
        setSoftskill(arrayWithoutUndefined)
      }
      
    const handleGetUser = async () => {
        const user = await getUser();
        await setUserDetail(user);
        if (user?.gender != null){
            setFirstName(user?.firstName);
            setLastName(user?.lastName);
            setEducation(user?.functionName);
            setQualification(user?.education);
            setFormValues({
                ...formValues,
                gender: user?.gender
              });
        }

        if (user?.hardskills != null){
            const str :any = user?.hardskills 
            const arr = str.split(",").map((item: string) => item.trim());
            if (arr !=null ){
                setHardskill(arr);}
        }
        if (user?.softskills != null){
            const str :any = user?.softskills 
            const arr = str.split(",").map((item: string) => item.trim());
            if (arr !=null ){
                setSoftskill(arr);}
        }
    }

        
    const handleSubmit = () => {
        try {
            // const data = new FormData(e.target);
            if (hardskills.length < 3) {
                toast.warn("Vul alsjeblieft meer dan twee interessante technologien in!")
              } else if (softskills.length  < 3) {
                toast.warn("Vul alsjeblieft meer dan twee softskills in!")
              } else {
                updateProfile('', userDetail.id, education, firstName, lastName ,
                    qualification, formValues.gender, hardskills, softskills)
                toast.success("Je account is opgeslagen");
                navigate('/');
              }

            setSearchHardSkill('')
            setSearchSoftSkill('')
        } catch (error){
            toast.warn("Iets is verkeerd gegaan.")
        }

        // toast.success("Je account is opgeslagen");
    }
    return (
        <div className="mt-16 px-2 md:px-12 justify-center">
            <div className="px-2 md:px-16 flex flex-col md:items-center">
                <div className="flex justify-center items-center">
                    <h1 className="text-2xl font-bold">Mijn profiel</h1>
                </div>
                {/*<form className="flex flex-col justify-center" onSubmit={e => handleSubmit(e)}>*/}
                    <div className="flex flex-col mt-5 mb-5 gap-5 w-full">
                        <div className="md:flex block w-full md:gap-4">
                            <div className="flex flex-col w-full md:mb-0 mb-4">
                                <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Voornaam: </label>
                                <TextInput
                                    type="text"
                                    name="fname"
                                    defaultValue={firstName || ''}
                                    required
                                    onChange={handleFirstNameChange}
                                    //    readOnly
                                />
                            </div>
                            <div className="flex flex-col w-full md:mb-0 mb-4">
                                <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Achternaam: </label>
                                <TextInput
                                    type="text"
                                    name="lname"
                                    defaultValue={lastName || ''}
                                    required
                                    onChange={handleLastNameChange}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Email </label>
                            <TextInput
                                type="email"
                                name="email"
                                defaultValue={userDetail?.email || ''}
                                required
                                readOnly
                            />
                        </div>
                        {/* <div className="md:flex block w-full gap-4"> */}
                        {/* <div className="flex flex-col md:mb-0 mb-4 ">
                                    <label> Bedrijf: </label>
                                    <input className="border border-black rounded-lg"
                                           type="text"
                                           name="company"
                                           defaultValue={userDetail?.company || ''}
                                           required
                                           readOnly
                                    />
                                </div> */}
                        <div className="flex flex-col ">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Wat is uw functie? </label>
                            <TextInput
                                type="text"
                                name="functionName"
                                id="functionName"
                                defaultValue={education || ''}
                                required
                                onChange={handleEducatChange}

                            />
                            {/* </div> */}
                        </div>
                        <div className="flex flex-col ">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Wat is uw hoogst behaalde opleidingsniveau? </label>
                            <select className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                                    id="education" name="education" value={qualification || ''}
                                    onChange= {handleQualificationChange}>
                                <option value="---------------">---------------</option>
                                <option value="Basisschool">Basisschool</option>
                                <option value="Middelbaar beroepsonderwijs">Middelbaar beroepsonderwijs</option>
                                <option value="Hoger beroepsonderwijs">Hoger beroepsonderwijs</option>
                                <option value="Universiteit">Universiteit</option>
                                <option value="Universitair gespecialiseerd diploma (Doctoraal, Juridisch)">Universitair gespecialiseerd diploma (Doctoraal, Juridisch)</option>
                            </select>
                        </div>
                        <div className="flex flex-col ">
                            <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Wat is uw geslacht? </label>
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Man"
                                    checked={formValues.gender  === 'Man'}
                                    onChange={handleGenderChange}
                                /> <label className="text-[15px]  ml-2 font-medium text-gray-900 dark:text-gray-300">Man</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    name="gender"
                                    value="Vrouw"
                                    checked={ formValues.gender  === 'Vrouw'}
                                    onChange={handleGenderChange}
                                    
                                /><label className="text-[15px]  ml-2 font-medium text-gray-900 dark:text-gray-300">Vrouw</label>

                            </div>

                        </div>
                        <div className=" ">
                            <label className=" text-sm font-medium text-gray-900 dark:text-gray-300"> Welke technologien vind je interessant? </label>
                            <div  color="light"
                             onMouseOver={() => {
                                 setShowHardSkillList(true)
                             }}
                             onMouseOut={() => {
                                 setShowHardSkillList(false)
                             }}>
                           <TagsInput
                               value={hardskills}
                               onChange={handleHardskillChange}
                               name="hardskill"

                               // placeHolder="Enter learning domains"
                           />

                           <div className="relative bg-transparent cursor-pointer">
                               <div className={`${showHardSkillList ? 'block' : 'hidden'} absolute bg-white border shadow-md w-full z-15 top-0 transition-all max-h-[180px] overflow-y-auto `}>
                                   <div className="relative">
                                       <div
                                           className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                           <svg aria-hidden="true"
                                                className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                                                stroke="currentColor" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                     d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                           </svg>
                                       </div>
                                       <input type="search" id="default-search"
                                              className="block w-full px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-gray-300 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                              placeholder="Search skills..."
                                              required
                                              autoComplete="off"
                                              name="searchHardSkills"
                                              value={searchHardSkill}
                                              onChange={handleSearchSkillsChange}
                                       />
                                   </div>
                                   {
                                       showHardSkillList  &&
                                       <div className='w-full' >
                                           <div className="w-full py-1">

                                               {
                                                   HardSkillsList.filter((item) => item.name.toLowerCase()
                                                       .includes(searchHardSkill.toLowerCase())).map((item: any, idx:number) => (
                                                               <div  key={idx}
                                                                     onClick={() => addHardSkill(item?.name)}
                                                               >
                                                                   <li className={`${hardskills.includes(item?.name) ? 'text-primary cursor-not-allowed' : 'cursor-pointer hover:text-secondary '} px-1 py-[4px] mx-3 flex justify-between items-center `}>
                                                                       <span className="text-sm font-normal">{item?.name}</span>
                                                                   </li>
                                                               </div>

                                                   ))
                                               }
                                           </div>
                                       </div>
                                   }
                               </div>
                           </div>
                       </div>


                        </div>

                        <div className="flex flex-col ">
                                <label className="text-sm font-medium text-gray-900 dark:text-gray-300"> Welke softskills wil je verbeteren? </label>
                            <div  color="light"
                                  onMouseOver={() => setShowSoftSkillList(true)} onMouseOut={() => setShowSoftSkillList(false)}>
                                <TagsInput
                                    value={softskills}
                                    onChange={handleSoftskillChange}
                                    name="softskill"

                                    // placeHolder="Enter learning domains"
                                />

                                <div className="relative bg-transparent cursor-pointer">
                                    <div className={`${showSoftSkillList ? 'block' : 'hidden'} absolute bg-white border shadow-md w-full z-15 top-0 transition-all max-h-[150px] overflow-y-auto `}>
                                        <div className="relative">
                                            <div
                                                className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg aria-hidden="true"
                                                     className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                                                     stroke="currentColor" viewBox="0 0 24 24"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                                </svg>
                                            </div>
                                            <input type="search" id="default-search"
                                                   className="block w-full px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg  focus:ring-gray-300 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                   placeholder="Search skills..."
                                                   required
                                                   autoComplete="off"
                                                   name="searchSoftSkills"
                                                   value={searchSoftSkill}
                                                   onChange={handleSearchSkillsChange}
                                            />
                                        </div>
                                        {
                                            showSoftSkillList  &&
                                            <div className='w-full ' >
                                                <div className="w-full py-1">
                                                    {
                                                        SoftSkillsList.filter((item) => item.name.toLowerCase().includes(searchSoftSkill.toLowerCase())).map((item: any, idx:number) => (
                                                            <div  key={idx}
                                                                  onClick={() => addSoftSkill(item?.name)}
                                                            >
                                                                <li className={`${softskills.includes(item?.name) ? 'text-primary cursor-not-allowed' : 'cursor-pointer hover:text-secondary '} px-1 py-[4px] mx-3 flex justify-between items-center `}>
                                                                    <span className="text-sm font-normal">{item?.name}</span>
                                                                </li>
                                                            </div>
                                                        ))
                                                    }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col rounded-md">
                    <label className="text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="message">Mijn leerdoelen:</label>
                    <textarea
                    id="message"
                    name="message"
                    className="block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
                    // value={formValues.message}
                    // onChange={handleInputChange}
                    ></textarea>
                </div>

                    {/* <div className="flex flex-col ">
                    <label> Welke softskills wil je jezelf in verbeteren? </label>
    
                            <TagsInput 
                                        value={selected}
                                        // onChange={/setSelected}
                                        name="Softskill"
                                        // placeHolder="Enter learning domains"
                                    /></div> */}
                                    
                         {/* <div className=" ">
                                    <label> Wat zijn jouw leerdoelen? </label> 
                                   
                                    <input className="border border-black rounded-lg flex h-48 text-start"
                                           type="text"
                                           name="learngoals"
                                           id="learngoals"  
                                           defaultValue={''}
                                        //    required
                                        /> */}
                         
                    
                             {/* </div>        */}
                            <div className="flex justify-end">
                                <Button color="dark" onClick={handleSubmit}>
                                    Opslaan
                                </Button>
                            </div>
                        </div>
                {/*</form>*/}
            </div>
        </div>
    );
};

export default React.memo(Profile);
