import React, { useEffect, useState, useContext } from 'react';
import SearchCardsList from "../CourseRecommendation/SearchCardsList";
import Filters from "../../components/shared/Filters";
import {Filter, FilterTypes} from "../../model/filter/filterInterface";
import {RiFilter3Line} from "react-icons/ri";
import {useNavigate, useSearchParams} from "react-router-dom";
import { searchQueries } from "../../routes"

const FilterPanels = ({ courseList, loading } : FilterTypes ) => {
    const [filterButton, setFilterButton] = useState<boolean>(true);
    const [smFilter, setSmFilter] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>({})
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(5000);

    const [search] = useSearchParams();
    const q = search.get("q");
    const navigate = useNavigate()
    const searchContext = useContext(searchQueries);

    useEffect(() => {
        if(smFilter){
            document.body.style.overflow = "hidden";
        }
        if(!smFilter){
            document.body.style.overflow = "unset";
        }
    }, [smFilter]);

    const clearFilter = () => {
        setFilter({})
        setMin(0)
        setMax(5000)
        setSelectedOptions(null)
        searchContext?.setSearchbarText("")
        searchContext?.handleSearch({}, true)
        let searchQuery = '/search/'
        navigate(searchQuery);
    }

    return (
        <div>
            <div className="lg:hidden block">
                <div className={`${smFilter ? 'fixed top-0 w-screen h-screen bg-Sidebar bg-opacity-80 z-30' : ''}`}>
                    <div className={`top-0 right-0 fixed w-full h-full z-10 ${smFilter ? 'translate-x-0' : 'translate-x-[500%]'} ease-in-out duration-500`}>
                        <div className="flex h-full w-full justify-end">
                            <div className="flex justify-end pr-4 w-full" onClick={() => setSmFilter(false)}>
                                <div className="w-11 h-11 min-w-[2.25rem] max-h-11 bg-white rounded-full text-center mt-3 flex justify-center text-lg items-center cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.1" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="w-[18rem] sm:min-w-[18rem] min-w-[18rem] bg-white h-full p-3 overflow-y-auto">
                                <div className="border-b border-b-1 pb-3">
                                    {
                                        loading ? 
                                        <h1 className="text-base font-normal !text-transparent lg:block hidden">{courseList?.length} results</h1>
                                         :
                                        <h1 className="text-lg text-primary font-medium lg:hidden block">{courseList?.length} results {filter.category && <span>in de categorie: {filter?.category}</span>}</h1>
                                    }
                                </div>
                                <Filters
                                    minValue={min}
                                    setMinValue={setMin}
                                    maxValue={max}
                                    setMaxValue={setMax}
                                    courseList={courseList}
                                    filter={filter}
                                    setFilter={setFilter}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                                <div className="flex justify-center mt-20">
                                    <div className="bg-[#1c1d1f] font-bold w-full p-3 text-white text-center cursor-pointer shadow-md" onClick={() => setSmFilter(false)}>
                                        Done
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:container md:mx-auto my-10 px-4 sm:px-4 md:px-0 relative">
                {
                    loading  ?
                     <h1 className="my-4 text-3xl font-normal text-transparent">hidden</h1>
                      :
                       !q ? 
                       <h1 className="my-4 text-3xl font-normal">{courseList?.length} resultaten gevonden {filter.category && (<span>in de categorie: {filter?.category}</span>)}</h1>:
                        <h1 className="my-4 text-3xl font-normal">{courseList?.length} results for "{q}"</h1>
                }
                <div className="flex sm:justify-between my-4">
                    <div className="flex gap-2 sm:w-[270px] w-full">
                        <div className="flex items-center border border-black text-md font-semibold rounded-none px-[0.8rem] py-2 hover:bg-[#F5F5F5] cursor-pointer"
                             onClick={() => {
                                 setFilterButton(!filterButton)
                                 setSmFilter(!smFilter)
                             }}
                        >
                            <RiFilter3Line className="w-[18px] h-[19px] mr-1"/>
                            <p>Filter</p>
                        </div>

                        <div>
                            <p className="flex items-center border border-black text-md font-semibold cursor-pointer px-[0.8rem] py-2 hover:bg-[#F5F5F5]" onClick={clearFilter}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" viewBox="0 0 24 24">
                                        <path d="M10.83,8H21V6H8.83L10.83,8z M15.83,13H18v-2h-4.17L15.83,13z M14,16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h0.17 L1.39,4.22l1.41-1.41l18.38,18.38l-1.41,1.41L14,16.83z"/>
                                    </svg>
                                </span>
                                Verwijder filters
                            </p>
                        </div>
                    </div>
                    <div className="lg:block hidden">
                        {
                            loading ? <h1 className="text-base font-normal !text-transparent lg:block hidden">{courseList?.length} results</h1> : <h1 className="text-base text-[#6A6F73] font-normal lg:block hidden">{courseList?.length} results</h1>
                        }
                    </div>
                </div>
                <div className={filterButton ? "flex flex-row" : ""}>
                    {
                        filterButton &&
                        <div>
                            <div className="mr-3 !w-[245px] min-w-[245px] lg:block hidden">
                                <Filters
                                    minValue={min}
                                    setMinValue={setMin}
                                    maxValue={max}
                                    setMaxValue={setMax}
                                    courseList={courseList}
                                    filter={filter}
                                    setFilter={setFilter}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                            </div>
                        </div>
                    }
                    <div className={filterButton ? "w-full lg:ml-6" : ""}>
                        <SearchCardsList loading={loading} courseList={courseList}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default React.memo(FilterPanels);